import {
  ADMIN_SUMMARY,
  ADMIN_SUMMARY_SUCCESS,
  ALL_PUBLISHERS,
  ALL_PUBLISHERS_SUCCESS,
  DELETE_PUBLISHER,
  DELETE_PUBLISHER_ERROR,
  DELETE_PUBLISHER_SUCCESS,
  ACCREDITATION_CREATION_REPORT,
  ACCREDITATION_CREATION_REPORT_SUCCESS,
  PUBLISHERS_REGISTRATION_REPORT,
  PUBLISHERS_REGISTRATION_REPORT_SUCCESS,
  READER_LOGS_REPORT,
  READER_LOGS_REPORT_SUCCESS,
  RESTORE_PUBLISHER,
  RESTORE_PUBLISHER_ERROR,
  RESTORE_PUBLISHER_SUCCESS,
  TEMPLATES_USAGE_REPORT,
  TEMPLATES_USAGE_REPORT_SUCCESS,
} from 'redux/contants';

export const adminSummary = () => ({
  type: ADMIN_SUMMARY,
  payload: {},
});

export const adminSummarySuccess = (summary) => ({
  type: ADMIN_SUMMARY_SUCCESS,
  payload: summary,
});

export const getTemplatesUsageReport = (args) => ({
  type: TEMPLATES_USAGE_REPORT,
  payload: { args },
});

export const getTemplatesUsageReportSuccess = (report) => ({
  type: TEMPLATES_USAGE_REPORT_SUCCESS,
  payload: report,
});

export const getPublishersRegistrationReport = (args) => ({
  type: PUBLISHERS_REGISTRATION_REPORT,
  payload: { args },
});

export const getPublishersRegistrationReportSuccess = (report) => ({
  type: PUBLISHERS_REGISTRATION_REPORT_SUCCESS,
  payload: report,
});

export const getAccreditationsCreationReport = (args) => ({
  type: ACCREDITATION_CREATION_REPORT,
  payload: { args },
});

export const getAccreditationsCreationReportSuccess = (report) => ({
  type: ACCREDITATION_CREATION_REPORT_SUCCESS,
  payload: report,
});

export const getReaderLogsReport = (args) => ({
  type: READER_LOGS_REPORT,
  payload: { args },
});

export const getReaderLogsReportSuccess = (report) => ({
  type: READER_LOGS_REPORT_SUCCESS,
  payload: report,
});

export const allPublishers = (args) => ({
  type: ALL_PUBLISHERS,
  payload: { args },
});

export const allPublishersSuccess = (publishers) => ({
  type: ALL_PUBLISHERS_SUCCESS,
  payload: publishers,
});

export const deletePublisher = (args) => ({
  type: DELETE_PUBLISHER,
  payload: { args },
});

export const deletePublisherSuccess = (publishers) => ({
  type: DELETE_PUBLISHER_SUCCESS,
  payload: publishers,
});

export const deletePublisherError = () => ({
  type: DELETE_PUBLISHER_ERROR,
  payload: {},
});

export const restorePublisher = (args) => ({
  type: RESTORE_PUBLISHER,
  payload: { args },
});

export const restorePublisherSuccess = (publishers) => ({
  type: RESTORE_PUBLISHER_SUCCESS,
  payload: publishers,
});

export const restorePublisherError = () => ({
  type: RESTORE_PUBLISHER_ERROR,
  payload: {},
});

/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { apolloClient } from 'redux/sagas';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { userRoot } from 'constants/defaultValues';
import { handleErrorMessage, handleSuccessMessage } from 'helpers/Utils';
import { encryptOnClient } from 'services/crypto';
import {
  BULK_DELETE_ACCREDITATIONS,
  BULK_POSTPONE_EXPIRATION_ACCREDITATIONS,
  BULK_RESTORE_ACCREDITATIONS,
  CREATE_ACCREDITATION,
  ACCREDITATION_ISSUE_REPORTS,
  ACCREDITATION_READER_LOGS,
  GET_ACCREDITATION_FILE_URL,
  APPROVE_ACCREDITATION,
  REJECT_ACCREDITATION,
  REVISE_ACCREDITATION,
  GET_ACCREDITATION,
  UPDATE_ACCREDITATION,
} from 'redux/contants';
import {
  createAccreditationSuccess,
  getAccreditationIssueReportsSuccess,
  getAccreditationFileUrlSuccess,
  getAccreditationReaderLogsSuccess,
  bulkDeleteAccreditationsSuccess,
  bulkDeleteAccreditationsError,
  bulkRestoreAccreditationsSuccess,
  bulkRestoreAccreditationsError,
  bulkPostponeExpirationAccreditationsSuccess,
  bulkPostponeExpirationAccreditationsError,
  createAccreditationError,
} from 'redux/actions';
import {
  BULK_DELETE_ACCREDITATIONS_QUERY,
  BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_QUERY,
  BULK_RESTORE_ACCREDITATIONS_QUERY,
  CREATE_ACCREDITATION_QUERY,
  ACCREDITATION_ISSUE_REPORTS_QUERY,
  ACCREDITATION_READER_LOGS_QUERY,
  GET_ACCREDITATION_FILE_URL_QUERY,
  APPROVE_ACCREDITATION_QUERY,
  REJECT_ACCREDITATION_QUERY,
  REVISE_ACCREDITATION_QUERY,
  GET_ACCREDITATION_QUERY,
  UPDATE_ACCREDITATION_QUERY,
} from './queries';
import {
  approveAccreditationSuccess,
  getAccreditationError,
  getAccreditationSuccess,
  rejectAccreditationError,
  rejectAccreditationSuccess,
  reviseAccreditationError,
  reviseAccreditationSuccess,
  updateAccreditationError,
  updateAccreditationSuccess,
} from './actions';

export function* watchCreateAccreditation() {
  yield takeEvery(CREATE_ACCREDITATION, createAccreditationGenerator);
}
export const CREATE_ACCREDITATION_API = async (accreditation) => {
  return apolloClient.mutate({
    mutation: CREATE_ACCREDITATION_QUERY,
    variables: {
      nickname: accreditation.nickname, // Can't encrypt yet without messing up search/paging (TODO#142)
      salt: accreditation.salt, // Random, no encryption needed
      url: accreditation.url, // Random, no encryption needed
      jsonPayload: accreditation.jsonPayload, // Already encrypted through 0K
      type: accreditation.type, // Acceptable that the type name is in clear
      encryptionVersion: accreditation.encryptionVersion,
      metadata: encryptOnClient(accreditation.metadata),
      tasks: accreditation.tasks,
    },
  });
};
export function* createAccreditationGenerator({ payload }) {
  const { accreditation, history } = payload;
  try {
    const {
      data: { createAccreditation },
    } = yield call(CREATE_ACCREDITATION_API, accreditation);
    yield put(
      createAccreditationSuccess({
        nickname: createAccreditation.nickname,
        url: createAccreditation.url,
        salt: createAccreditation.salt,
        json_payload: createAccreditation.json_payload,
      })
    );
    yield put(createAccreditationSuccess());
    handleSuccessMessage(
      'New Accreditation has been created. Please process the payment so that your accreditation can be processed by the administrator.',
      'New Accreditation Created!'
    );
    history.push(
      `${userRoot}/accreditation/payment/0/${createAccreditation.uuid}`
    );
  } catch (e) {
    handleErrorMessage(e, 'Create Accreditation Error', history);
    yield put(createAccreditationError());
  }
}

export function* watchUpdateAccreditation() {
  yield takeEvery(UPDATE_ACCREDITATION, updateAccreditationGenerator);
}
export const UPDATE_ACCREDITATION_API = async (accreditation) => {
  return apolloClient.mutate({
    mutation: UPDATE_ACCREDITATION_QUERY,
    variables: {
      id: accreditation.id,
      tasks: accreditation.tasks,
    },
  });
};
export function* updateAccreditationGenerator({ payload }) {
  const { accreditation, history } = payload;
  try {
    const {
      data: { updateAccreditation },
    } = yield call(UPDATE_ACCREDITATION_API, accreditation);
    yield put(updateAccreditationSuccess());
    handleSuccessMessage(updateAccreditation, 'Accreditation Updated!');
    history.push(`${userRoot}/dashboard`);
  } catch (e) {
    handleErrorMessage(e, 'Update Accreditation Error', history);
    yield put(updateAccreditationError());
  }
}

export function* watchGetAccreditationFileUrl() {
  yield takeEvery(GET_ACCREDITATION_FILE_URL, getAccreditationFileUrlGenerator);
}
const GET_ACCREDITATION_FILE_URL_API = async () => {
  return apolloClient.query({
    query: GET_ACCREDITATION_FILE_URL_QUERY,
    variables: {},
  });
};
function* getAccreditationFileUrlGenerator() {
  try {
    const {
      data: { getAccreditationFileUrl },
    } = yield call(GET_ACCREDITATION_FILE_URL_API);
    yield put(getAccreditationFileUrlSuccess(getAccreditationFileUrl));
  } catch (e) {
    handleErrorMessage(e, 'Get Accreditation File Url Error', false);
  }
}

export function* watchAccreditationIssueReports() {
  yield takeEvery(
    ACCREDITATION_ISSUE_REPORTS,
    accreditationIssueReportsGenerator
  );
}
const ACCREDITATION_ISSUE_REPORTS_API = async (args) => {
  return apolloClient.query({
    query: ACCREDITATION_ISSUE_REPORTS_QUERY,
    variables: {
      accreditationId: args.accreditation_id,
    },
  });
};
function* accreditationIssueReportsGenerator({ payload }) {
  try {
    const {
      data: { accreditationIssueReports },
    } = yield call(ACCREDITATION_ISSUE_REPORTS_API, payload.args);
    yield put(getAccreditationIssueReportsSuccess(accreditationIssueReports));
  } catch (e) {
    handleErrorMessage(e, 'Accreditation Issue Reports', false);
  }
}

export function* watchAccreditationReaderLogs() {
  yield takeEvery(ACCREDITATION_READER_LOGS, accreditationReaderLogsGenerator);
}
const ACCREDITATION_READER_LOGS_API = async (args) => {
  return apolloClient.query({
    query: ACCREDITATION_READER_LOGS_QUERY,
    variables: {
      accreditationId: args.id,
    },
  });
};
function* accreditationReaderLogsGenerator({ payload }) {
  try {
    const {
      data: { accreditationReaderLogs },
    } = yield call(ACCREDITATION_READER_LOGS_API, payload.args);
    yield put(getAccreditationReaderLogsSuccess(accreditationReaderLogs));
  } catch (e) {
    handleErrorMessage(e, 'Accreditation Reader Logs', false);
  }
}

export function* watchBulkDeleteAccreditations() {
  yield takeEvery(
    BULK_DELETE_ACCREDITATIONS,
    bulkDeleteAccreditationsGenerator
  );
}
const BULK_DELETE_ACCREDITATIONS_API = async (args) => {
  return apolloClient.mutate({
    mutation: BULK_DELETE_ACCREDITATIONS_QUERY,
    variables: {
      ids: args.ids,
    },
  });
};
function* bulkDeleteAccreditationsGenerator({ payload }) {
  try {
    const {
      data: { bulkDeleteAccreditations },
    } = yield call(BULK_DELETE_ACCREDITATIONS_API, payload.args);
    yield put(bulkDeleteAccreditationsSuccess());
    handleSuccessMessage(bulkDeleteAccreditations, 'Accreditations Deleted');
  } catch (e) {
    yield put(bulkDeleteAccreditationsError());
    handleErrorMessage(e, 'Accreditations Deleted', false);
  }
}

export function* watchBulkRestoreAccreditations() {
  yield takeEvery(
    BULK_RESTORE_ACCREDITATIONS,
    bulkRestoreAccreditationsGenerator
  );
}
const BULK_RESTORE_ACCREDITATIONS_API = async (args) => {
  return apolloClient.mutate({
    mutation: BULK_RESTORE_ACCREDITATIONS_QUERY,
    variables: {
      ids: args.ids,
    },
  });
};
function* bulkRestoreAccreditationsGenerator({ payload }) {
  try {
    const {
      data: { bulkRestoreAccreditations },
    } = yield call(BULK_RESTORE_ACCREDITATIONS_API, payload.args);
    yield put(bulkRestoreAccreditationsSuccess());
    handleSuccessMessage(bulkRestoreAccreditations, 'Accreditations Restored');
  } catch (e) {
    yield put(bulkRestoreAccreditationsError());
    handleErrorMessage(e, 'Accreditations Restored', false);
  }
}

export function* watchBulPostponeExpirationAccreditations() {
  yield takeEvery(
    BULK_POSTPONE_EXPIRATION_ACCREDITATIONS,
    bulkPostponeExpirationAccreditationsGenerator
  );
}
const BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_API = async (args) => {
  return apolloClient.mutate({
    mutation: BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_QUERY,
    variables: {
      ids: args.ids,
      expiresAt: args.expires_at,
    },
  });
};
function* bulkPostponeExpirationAccreditationsGenerator({ payload }) {
  try {
    const {
      data: { bulkPostponeAccreditationExpiration },
    } = yield call(BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_API, payload.args);
    yield put(bulkPostponeExpirationAccreditationsSuccess());
    handleSuccessMessage(
      bulkPostponeAccreditationExpiration,
      'Accreditations Expiration Date Updated'
    );
  } catch (e) {
    yield put(bulkPostponeExpirationAccreditationsError());
    handleErrorMessage(e, 'Accreditations Expiration Date Update', false);
  }
}

export function* watchApproveAccreditation() {
  yield takeEvery(APPROVE_ACCREDITATION, approveAccreditationGenerator);
}
const APPROVE_ACCREDITATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: APPROVE_ACCREDITATION_QUERY,
    variables: {
      approveAccreditationId: args.id,
      accreditationLetter: args.accreditationLetter,
      url: args.url,
      name: args.name,
      attachment: args.attachment,
      password: args.password,
    },
  });
};
function* approveAccreditationGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { approveAccreditation },
    } = yield call(APPROVE_ACCREDITATION_API, args);
    handleSuccessMessage(approveAccreditation, 'Accreditation Approved');
    yield put(approveAccreditationSuccess());
  } catch (e) {
    yield put(approveAccreditationSuccess());
    handleErrorMessage(e, 'Approve Accreditation Error', null);
  }
}

export function* watchRejectAccreditation() {
  yield takeEvery(REJECT_ACCREDITATION, rejectAccreditationGenerator);
}
const REJECT_ACCREDITATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: REJECT_ACCREDITATION_QUERY,
    variables: {
      rejectAccreditationId: args.id,
      comments: args.comments,
      name: args.name,
    },
  });
};
function* rejectAccreditationGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { rejectAccreditation },
    } = yield call(REJECT_ACCREDITATION_API, args);
    handleSuccessMessage(rejectAccreditation, 'Accreditation Rejected');
    yield put(rejectAccreditationSuccess());
  } catch (e) {
    yield put(rejectAccreditationError());
    handleErrorMessage(e, 'Approve Rejection Error', null);
  }
}

export function* watchReviseAccreditation() {
  yield takeEvery(REVISE_ACCREDITATION, reviseAccreditationGenerator);
}
const REVISE_ACCREDITATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: REVISE_ACCREDITATION_QUERY,
    variables: {
      reviseAccreditationId: args.id,
      comments: args.comments,
      name: args.name,
    },
  });
};
function* reviseAccreditationGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { reviseAccreditation },
    } = yield call(REVISE_ACCREDITATION_API, args);
    handleSuccessMessage(reviseAccreditation, 'Accreditation Revised');
    yield put(reviseAccreditationSuccess());
  } catch (e) {
    yield put(reviseAccreditationError());
    handleErrorMessage(e, 'Approve Rejection Error', null);
  }
}

export function* watchGetAccreditation() {
  yield takeEvery(GET_ACCREDITATION, getAccreditationGenerator);
}
const GET_ACCREDITATION_API = async (args) => {
  return apolloClient.query({
    query: GET_ACCREDITATION_QUERY,
    variables: {
      uuid: args.uuid,
    },
  });
};
function* getAccreditationGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { getAccreditation },
    } = yield call(GET_ACCREDITATION_API, args);
    yield put(getAccreditationSuccess(getAccreditation));
  } catch (e) {
    yield put(getAccreditationError());
    handleErrorMessage(e, 'Accreditation Fetch Error', null);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchCreateAccreditation),
    fork(watchUpdateAccreditation),
    fork(watchGetAccreditationFileUrl),
    fork(watchAccreditationIssueReports),
    fork(watchAccreditationReaderLogs),
    fork(watchBulkDeleteAccreditations),
    fork(watchBulkRestoreAccreditations),
    fork(watchBulPostponeExpirationAccreditations),
    fork(watchApproveAccreditation),
    fork(watchRejectAccreditation),
    fork(watchReviseAccreditation),
    fork(watchGetAccreditation),
  ]);
}

/* eslint-disable import/no-cycle */
/* eslint-disable no-use-before-define */
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { GET_PAYMENT_METHODS, PAYMENT } from 'redux/contants';
import { apolloClient } from 'redux/sagas';
import { handleErrorMessage, handleSuccessMessage } from 'helpers/Utils';
import { userRoot } from 'constants/defaultValues';
import { GET_PAYMENT_METHODS_QUERY, PAYMENT_QUERY } from './queries';
import {
  getPaymentMethodsError,
  getPaymentMethodsSuccess,
  paymentError,
  paymentSuccess,
} from './actions';

export function* watchPayment() {
  yield takeLatest(PAYMENT, paymentGenerator);
}
const PAYMENT_API = async (args) => {
  return apolloClient.mutate({
    mutation: PAYMENT_QUERY,
    variables: {
      paymentMethodId: args?.payment_method_id,
      uuid: args?.uuid,
      renew: args?.renew,
    },
  });
};
function* paymentGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const {
      data: { payment },
    } = yield call(PAYMENT_API, args);
    yield put(paymentSuccess(payment));
    handleSuccessMessage(payment, 'Payment Success');
    history.push(`${userRoot}`);
  } catch (e) {
    handleErrorMessage(e, 'Payment Error', null);
    yield put(paymentError());
    history.push(`${userRoot}`);
  }
}

export function* watchGetPaymentMethods() {
  yield takeLatest(GET_PAYMENT_METHODS, getPaymentMethodsGenerator);
}
const GET_PAYMENT_METHODS_API = async () => {
  return apolloClient.query({
    query: GET_PAYMENT_METHODS_QUERY,
    variables: {},
  });
};
function* getPaymentMethodsGenerator() {
  try {
    const {
      data: { getPaymentMethods },
    } = yield call(GET_PAYMENT_METHODS_API);
    yield put(getPaymentMethodsSuccess(getPaymentMethods));
  } catch (e) {
    handleErrorMessage(e, 'Get Payment Methods Error', null);
    yield put(getPaymentMethodsError());
  }
}

export default function* rootSaga() {
  yield all([fork(watchPayment), fork(watchGetPaymentMethods)]);
}

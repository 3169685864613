import { getCurrentUser } from 'helpers/Utils';
import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_ERROR,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  READ_ACCREDITATION,
  READ_ACCREDITATION_SUCCESS,
  READ_ACCREDITATION_ERROR,
  VERIFY_RECAPTCHA,
  VERIFY_RECAPTCHA_SUCCESS,
  VERIFY_RECAPTCHA_ERROR,
  CREATE_READER_LOG,
  CREATE_READER_LOG_SUCCESS,
  UPDATE_PERSONAL_INFORMATION_SUCCESS,
  SEND_RECOVERY_CODE,
  SEND_RECOVERY_CODE_SUCCESS,
  VERIFY_RECOVERY_CODE,
  VERIFY_RECOVERY_CODE_SUCCESS,
  VERIFY_RECOVERY_CODE_ERROR,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERROR,
  GET_ACCREDITATION_FILE,
  GET_ACCREDITATION_FILE_SUCCESS,
  GET_ACCREDITATION_FILE_ERROR,
  CREATE_ISSUE_REPORT,
  CREATE_ISSUE_REPORT_SUCCESS,
  READ_ACCREDITATION_LETTER,
  READ_ACCREDITATION_LETTER_SUCCESS,
  READ_ACCREDITATION_LETTER_ERROR,
  CLEAR_ACCREDITATION_LETTER,
} from 'redux/contants';

const INIT_STATE = {
  currentUser: getCurrentUser(),
  accreditationLetter: null,
  forgotUserMail: '',
  newPassword: '',
  resetPasswordCode: '',
  loading: false,
  error: '',
  readAccreditation: null,
  readAccreditationError: null,
  reCaptcha: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PERSONAL_INFORMATION_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...getCurrentUser,
          user: action.payload,
        },
      };
    case VERIFY_RECAPTCHA:
      return { ...state, loading: true, error: '', reCaptcha: false };
    case VERIFY_RECAPTCHA_SUCCESS:
      return { ...state, loading: false, error: '', reCaptcha: true };
    case VERIFY_RECAPTCHA_ERROR:
      return { ...state, loading: false, error: '', reCaptcha: false };
    case LOGIN_USER:
      return { ...state, loading: true, error: '' };
    case READ_ACCREDITATION_LETTER:
      return { ...state, loading: true, error: '', accreditationLetter: null };
    case READ_ACCREDITATION_LETTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        accreditationLetter: action.payload,
      };
    case READ_ACCREDITATION_LETTER_ERROR:
      return {
        ...state,
        loading: false,
        error: '',
        accreditationLetter: null,
      };
    case READ_ACCREDITATION:
      return { ...state, loading: true, error: '' };
    case READ_ACCREDITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        readAccreditation: action.payload.accreditation,
        readAccreditationError: null,
      };
    case CREATE_READER_LOG:
      return { ...state, loading: true, error: '' };
    case CREATE_READER_LOG_SUCCESS:
      return { ...state, loading: false, error: '' };
    case CREATE_ISSUE_REPORT:
      return { ...state, loading: true, error: '' };
    case CREATE_ISSUE_REPORT_SUCCESS:
      return { ...state, loading: false, error: '' };
    case READ_ACCREDITATION_ERROR:
      return {
        ...state,
        loading: false,
        error: '',
        readAccreditation: null,
        readAccreditationError: action.payload.error,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        currentUser: action.payload,
        error: '',
      };
    case LOGIN_USER_ERROR:
      return {
        ...state,
        loading: false,
        currentUser: null,
        error: action.payload.message,
      };
    case FORGOT_PASSWORD:
      return { ...state, loading: true, error: '' };
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        forgotUserMail: action.payload,
        error: '',
      };
    case FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        forgotUserMail: '',
        error: action.payload.message,
      };
    case RESET_PASSWORD:
      return { ...state, loading: true, error: '' };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: '' };
    case RESET_PASSWORD_ERROR:
      return { ...state, loading: false, error: '' };
    case VERIFY_USER:
      return { ...state, loading: true, error: '' };
    case VERIFY_USER_SUCCESS:
      return { ...state, loading: false, error: '' };
    case VERIFY_USER_ERROR:
      return { ...state, loading: false, error: '' };
    case VERIFY_RECOVERY_CODE:
      return { ...state, loading: true, error: '' };
    case VERIFY_RECOVERY_CODE_SUCCESS:
      return { ...state, loading: false, error: '' };
    case VERIFY_RECOVERY_CODE_ERROR:
      return { ...state, loading: false, error: '' };
    case SEND_RECOVERY_CODE:
      return { ...state, loading: true, error: '' };
    case SEND_RECOVERY_CODE_SUCCESS:
      return { ...state, loading: false, error: '' };
    case REGISTER_USER:
      return { ...state, loading: true, error: '' };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, error: '' };
    case REGISTER_USER_ERROR:
      return { ...state, loading: false, error: '' };
    case LOGOUT_USER:
      return { ...state, currentUser: null, error: '' };
    case GET_ACCREDITATION_FILE:
      return { ...state, loading: true, error: '', accreditationFile: null };
    case CLEAR_ACCREDITATION_LETTER:
      return { ...state, loading: false, error: '', accreditationLetter: null };
    case GET_ACCREDITATION_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        accreditationLetter: action.payload,
      };
    case GET_ACCREDITATION_FILE_ERROR:
      return { ...state, loading: false, error: '', accreditationFile: null };
    default:
      return { ...state };
  }
};

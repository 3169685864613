const { gql } = require('@apollo/client');

const UPDATE_PERSONAL_INFORMATION_QUERY = gql(`
    mutation($name: String!, $secondaryEmail: String!){
        updatePersonalInformation(name: $name, secondary_email: $secondaryEmail) {
            id, secondary_email, name, email, is_email_verified, deleted_at, admin_guid, stripe_customer_id
        }
    }`);

const GET_NOTIFICATIONS_QUERY = gql(`
    query GetNotifications {
        getNotifications {
            id, content, icon, created_at, seen, type, created_at, receiver { id, name }, sender { id, name }
        }
    }`);

const RESEND_VERIFICATION_EMAIL_QUERY = gql(`
    query($email: String!){
        resendVerificationEmail(email: $email) {
            status, message 
        }
    }`);

const MARK_NOTIFICATIONS_AS_SEEN_QUERY = gql(`
    mutation{
        markNotificationsAsSeen {
            id, content, icon, created_at, seen, type, created_at, receiver { id, name }, sender { id, name }
        }
    }`);

const UPDATE_PASSWORD_QUERY = gql(`
    mutation($newPassword: String!, $currentPassword: String!){
        updatePassword(new_password: $newPassword, current_password: $currentPassword) {
            status, message
        }
    }`);

const DELETE_USER_QUERY = gql(`
    mutation($password: String!){
        deleteUser(password: $password) {
            status, message
        }
    }`);

const ALL_ACCREDITATIONS_QUERY = gql(`
    query($limit: Int!, $page: Int!, $search: String!, $orderByField: OrderByFieldAccreditation!, $orderDirection: OrderDirection!, $userId: Int, $status: AccreditationStatus!){
        allAccreditations(limit: $limit, page: $page, search: $search, orderByField: $orderByField, orderDirection: $orderDirection, userId: $userId, status: $status) {
            rows {
                total_reader_logs, total_issue_reports, nickname, id, uuid, salt, url, created_at, deleted_at, type, expires_at, status
                user {
                    id, name, email
                }
                tasks {
                    id, slug, text, filename, type, file_size, file_type, filename_original
                }
                accreditation_audit_trails {
                    id, comments, action, created_at
                }
                transactions {
                    id, amount, created_at
                }
            }
            count, page, allPages, 
        }
    }`);

const POSTPONE_ACCREDITATION_EXPIRATION_QUERY = gql(`
    mutation($postponeEntryExpirationId: ID!, $expiresAt: String!, $isFuture: Boolean!){
        postponeEntryExpiration(id: $postponeEntryExpirationId, expires_at: $expiresAt, is_future: $isFuture) {
            status, message
        }
    }`);

const ACCREDITATIONS_SUMMARY_QUERY = gql(`
    query{
        accreditationsSummary {
            approvedAccreditations, rejectedAccreditations, unpaidAccreditations, submittedAccreditations, deletedAccreditations, underRevisionAccreditations, expiredAccreditations
        }
    }`);

const DELETE_ACCREDITATION_QUERY = gql(`
    mutation($deleteAccreditationId: ID!){
        deleteAccreditation(id: $deleteAccreditationId) {
            status, message
        }
    }`);

const RESTORE_ACCREDITATION_QUERY = gql(`
    mutation($restoreAccreditationId: ID!){
        restoreAccreditation(id: $restoreAccreditationId) {
            status, message
        }
    }`);

// TODO: Add 'metadata' and 'admin_metadata' here when available
const USER_SUMMARY_QUERY = gql(`
    query{
        userSummary{
          is_email_verified,
        }
    }`);

module.exports = {
  DELETE_ACCREDITATION_QUERY,
  ACCREDITATIONS_SUMMARY_QUERY,
  ALL_ACCREDITATIONS_QUERY,
  UPDATE_PERSONAL_INFORMATION_QUERY,
  UPDATE_PASSWORD_QUERY,
  DELETE_USER_QUERY,
  POSTPONE_ACCREDITATION_EXPIRATION_QUERY,
  USER_SUMMARY_QUERY,
  GET_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATIONS_AS_SEEN_QUERY,
  RESEND_VERIFICATION_EMAIL_QUERY,
  RESTORE_ACCREDITATION_QUERY,
};

const { gql } = require('@apollo/client');

const ADMIN_SUMMARY_QUERY = gql(`
  query{
    adminSummary {
      approvedAccreditations, rejectedAccreditations, submittedAccreditations, unpaidAccreditations, deletedAccreditations, underRevisionAccreditations, expiredAccreditations
      deletedUsers, pendingUsers, verifiedUsers
    }
  }`);

const TEMPLATES_USAGE_REPORT_QUERY = gql(`
  query($createdAtStart: String!, $createdAtEnd: String!){
    templatesUsageReport(createdAtStart: $createdAtStart, createdAtEnd: $createdAtEnd) {
      template, totalCount
    }
  }`);

const READER_LOGS_REPORT_QUERY = gql(`
  query($createdAtEnd: String!, $createdAtStart: String!){
    readerLogsReport(createdAtEnd: $createdAtEnd, createdAtStart: $createdAtStart) {
      id, name, nickname, total
    }
  }`);

const PUBLISHERS_REGISTRATION_REPORT_QUERY = gql(`
  query($createdAtStart: String!, $createdAtEnd: String!){
    publishersRegistrationReport(createdAtStart: $createdAtStart, createdAtEnd: $createdAtEnd) {
      name, secondary_email, created_at, deleted_at
    }
  }`);

const ENTRIES_CREATION_REPORT_QUERY = gql(`
  query($createdAtStart: String!, $createdAtEnd: String!){
    entriesCreationReport(createdAtStart: $createdAtStart, createdAtEnd: $createdAtEnd) {
      nickname, name, created_at, expires_at, template, deleted_at
    }
  }`);

const ALL_PUBLISHERS_QUERY = gql(`
  query($page: Int, $limit: Int, $search: String, $orderDirection: OrderDirection, $orderByField: OrderByFieldUsers, $paranoid: Boolean!){
    allUsers(page: $page, limit: $limit, search: $search, orderDirection: $orderDirection, orderByField: $orderByField, paranoid: $paranoid) {
      allPages, page, count, rows {
        id, name, created_at, deleted_at, accreditations {
          id, nickname
        }
      }
    }
  }`);

const DELETE_PUBLISHER_QUERY = gql(`
  mutation($deleteUserByIdId: Int!){
    deleteUserById(id: $deleteUserByIdId) {
      message, status
    }
  }`);

const RESTORE_PUBLISHER_QUERY = gql(`
  mutation($restoreUserByIdId: Int!){
    restoreUserById(id: $restoreUserByIdId) {
      message, status
    }
  }`);

module.exports = {
  ADMIN_SUMMARY_QUERY,
  ALL_PUBLISHERS_QUERY,
  DELETE_PUBLISHER_QUERY,
  RESTORE_PUBLISHER_QUERY,
  TEMPLATES_USAGE_REPORT_QUERY,
  READER_LOGS_REPORT_QUERY,
  PUBLISHERS_REGISTRATION_REPORT_QUERY,
  ENTRIES_CREATION_REPORT_QUERY,
};

export const UserRole = {
  Admin: 'ADMIN',
  Publisher: 'PUBLISHER',
};

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
  { id: 'en', name: 'English - LTR', direction: 'ltr' },
  { id: 'es', name: 'Español', direction: 'ltr' },
  { id: 'enrtl', name: 'English - RTL', direction: 'rtl' },
];

// The top pages when user is not logged in
export const homePage = '/';
export const ourServicesPage = 'our-services';
export const aboutUsPage = '/about-us';
export const contactUsPage = '/contact-us';
export const tryNowPage = '/'; // TODO: Create the '/try-now' page
export const faqPage = '/faq';
export const fraudPage = '/fraud-protection';
export const comparisonPage = '/comparison';
export const ourClientsPage = '/our-clients';
export const pricingPage = '/pricing';
export const securityPage = '/security';
export const termsPage = '/terms';

// The path to display when the user is logged in
export const userRoot = '/app';
export const adminRoot = '/admin';
export const userRegister = '/user/register';
export const userTasks = '/app/tasks';
export const userProfileSettings = '/app/account/profile-settings';

// Path to demos
export const liveDemo = 'https://safe-wire.com/secured/verify/PXZ9FEXW';

// TODO: Internationalize this
export const appNameWithSlogan =
  'Safe-Accreditation | Prevent Investment Fraud';
export const appSubTitle = 'CONFIRM INVESTMENT INFORMATION IN SECONDS!';
export const appNameWithCopyright = `© Safe-Accreditation ${new Date().getFullYear()}`;
export const buyUrl = 'https://1.envato.market/k4z0';
export const searchPath = `${userRoot}/pages/miscellaneous/search`;
export const servicePath = 'https://api.coloredstrategies.com';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.blueyale';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isAuthGuardActive = true;
export const colors = ['blueyale'];

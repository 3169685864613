/* SETTINGS */
export const CHANGE_LOCALE = 'CHANGE_LOCALE';

/* AUTH */
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_ERROR = 'FORGOT_PASSWORD_ERROR';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'RESET_PASSWORD_ERROR';
export const VERIFY_RECAPTCHA = 'VERIFY_RECAPTCHA';
export const VERIFY_RECAPTCHA_SUCCESS = 'VERIFY_RECAPTCHA_SUCCESS';
export const VERIFY_RECAPTCHA_ERROR = 'VERIFY_RECAPTCHA_ERROR';
export const VERIFY_USER = 'VERIFY_USER';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_ERROR = 'VERIFY_USER_ERROR';

/* PROFILE */
export const UPDATE_PERSONAL_INFORMATION = 'UPDATE_PERSONAL_INFORMATION';
export const UPDATE_PERSONAL_INFORMATION_SUCCESS =
  'UPDATE_PERSONAL_INFORMATION_SUCCESS';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

// ACCREDITATIONS
export const GET_ACCREDITATION = 'GET_ACCREDITATION';
export const GET_ACCREDITATION_SUCCESS = 'GET_ACCREDITATION_SUCCESS';
export const GET_ACCREDITATION_ERROR = 'GET_ACCREDITATION_ERROR';
export const APPROVE_ACCREDITATION = 'APPROVE_ACCREDITATION';
export const APPROVE_ACCREDITATION_SUCCESS = 'APPROVE_ACCREDITATION_SUCCESS';
export const APPROVE_ACCREDITATION_ERROR = 'APPROVE_ACCREDITATION_ERROR';
export const REJECT_ACCREDITATION = 'REJECT_ACCREDITATION';
export const REJECT_ACCREDITATION_SUCCESS = 'REJECT_ACCREDITATION_SUCCESS';
export const REJECT_ACCREDITATION_ERROR = 'REJECT_ACCREDITATION_ERROR';
export const REVISE_ACCREDITATION = 'REVISE_ACCREDITATION';
export const REVISE_ACCREDITATION_SUCCESS = 'REVISE_ACCREDITATION_SUCCESS';
export const REVISE_ACCREDITATION_ERROR = 'REVISE_ACCREDITATION_ERROR';
export const BULK_DELETE_ACCREDITATIONS = 'BULK_DELETE_ACCREDITATIONS';
export const BULK_DELETE_ACCREDITATIONS_SUCCESS =
  'BULK_DELETE_ACCREDITATIONS_SUCCESS';
export const BULK_DELETE_ACCREDITATIONS_ERROR =
  'BULK_DELETE_ACCREDITATIONS_ERROR';
export const BULK_RESTORE_ACCREDITATIONS = 'BULK_RESTORE_ACCREDITATIONS';
export const BULK_RESTORE_ACCREDITATIONS_SUCCESS =
  'BULK_RESTORE_ACCREDITATIONS_SUCCESS';
export const BULK_RESTORE_ACCREDITATIONS_ERROR =
  'BULK_RESTORE_ACCREDITATIONS_ERROR';
export const BULK_POSTPONE_EXPIRATION_ACCREDITATIONS =
  'BULK_POSTPONE_EXPIRATION_ACCREDITATIONS';
export const BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_SUCCESS =
  'BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_SUCCESS';
export const BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_ERROR =
  'BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_ERROR';
export const ACCREDITATION_ISSUE_REPORTS = 'ACCREDITATION_ISSUE_REPORTS';
export const ACCREDITATION_ISSUE_REPORTS_SUCCESS =
  'ACCREDITATION_ISSUE_REPORTS_SUCCESS';
export const ACCREDITATION_ISSUE_REPORTS_CLEAR =
  'ACCREDITATION_ISSUE_REPORTS_CLEAR';
export const ACCREDITATION_READER_LOGS = 'ACCREDITATION_READER_LOGS';
export const ACCREDITATION_READER_LOGS_SUCCESS =
  'ACCREDITATION_READER_LOGS_SUCCESS';
export const ACCREDITATION_READER_LOGS_CLEAR =
  'ACCREDITATION_READER_LOGS_CLEAR';
export const CLEAR_ACCREDITATION = 'CLEAR_ACCREDITATION';
export const CLEAR_ACCREDITATION_LETTER = 'CLEAR_ACCREDITATION_LETTER';
export const GET_ACCREDITATION_FILE_URL = 'GET_ACCREDITATION_FILE_URL';
export const GET_ACCREDITATION_FILE_URL_SUCCESS =
  'GET_ACCREDITATION_FILE_URL_SUCCESS';
export const GET_ACCREDITATION_FILE = 'GET_ACCREDITATION_FILE';
export const GET_ACCREDITATION_FILE_SUCCESS = 'GET_ACCREDITATION_FILE_SUCCESS';
export const GET_ACCREDITATION_FILE_ERROR = 'GET_ACCREDITATION_FILE_ERROR';
export const POSTPONE_ACCREDITATION_EXPIRATION =
  'POSTPONE_ACCREDITATION_EXPIRATION';
export const READ_ACCREDITATION_LETTER = 'READ_ACCREDITATION_LETTER';
export const READ_ACCREDITATION_LETTER_SUCCESS =
  'READ_ACCREDITATION_LETTER_SUCCESS';
export const READ_ACCREDITATION_LETTER_ERROR =
  'READ_ACCREDITATION_LETTER_ERROR';
export const READ_ACCREDITATION = 'READ_ACCREDITATION';
export const READ_ACCREDITATION_SUCCESS = 'READ_ACCREDITATION_SUCCESS';
export const CREATE_READER_LOG = 'CREATE_READER_LOG';
export const CREATE_READER_LOG_SUCCESS = 'CREATE_READER_LOG_SUCCESS';
export const READ_ACCREDITATION_ERROR = 'READ_ACCREDITATION_ERROR';
export const CREATE_ACCREDITATION = 'CREATE_ACCREDITATION';
export const CREATE_ACCREDITATION_SUCCESS = 'CREATE_ACCREDITATION_SUCCESS';
export const CREATE_ACCREDITATION_ERROR = 'CREATE_ACCREDITATION_ERROR';
export const UPDATE_ACCREDITATION = 'UPDATE_ACCREDITATION';
export const UPDATE_ACCREDITATION_SUCCESS = 'UPDATE_ACCREDITATION_SUCCESS';
export const UPDATE_ACCREDITATION_ERROR = 'UPDATE_ACCREDITATION_ERROR';
export const ALL_ACCREDITATIONS = 'ALL_ACCREDITATIONS';
export const DELETE_ACCREDITATION = 'DELETE_ACCREDITATION';
export const DELETE_ACCREDITATION_SUCCESS = 'DELETE_ACCREDITATION_SUCCESS';
export const RESTORE_ACCREDITATION = 'RESTORE_ACCREDITATION';
export const RESTORE_ACCREDITATION_SUCCESS = 'RESTORE_ACCREDITATION_SUCCESS';
export const ALL_ACCREDITATIONS_SUCCESS = 'ALL_ACCREDITATIONS_SUCCESS';
export const ACCREDITATIONS_SUMMARY = 'ACCREDITATIONS_SUMMARY';
export const ACCREDITATIONS_SUMMARY_SUCCESS = 'ACCREDITATIONS_SUMMARY_SUCCESS';
export const CREATE_ISSUE_REPORT = 'CREATE_ISSUE_REPORT';
export const CREATE_ISSUE_REPORT_SUCCESS = 'CREATE_ISSUE_REPORT_SUCCESS';

// USER
export const SEND_RECOVERY_CODE = 'SEND_RECOVERY_CODE';
export const SEND_RECOVERY_CODE_SUCCESS = 'SEND_RECOVERY_CODE_SUCCESS';
export const VERIFY_RECOVERY_CODE = 'VERIFY_RECOVERY_CODE';
export const VERIFY_RECOVERY_CODE_SUCCESS = 'SEND_RECOVERY_CODE_SUCCESS';
export const VERIFY_RECOVERY_CODE_ERROR = 'VERIFY_RECOVERY_CODE_ERROR';
export const USER_SUMMARY = 'USER_SUMMARY';
export const USER_SUMMARY_SUCCESS = 'USER_SUMMARY_SUCCESS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL';
export const RESEND_VERIFICATION_EMAIL_SUCCESS =
  'RESEND_VERIFICATION_EMAIL_SUCCESS';
export const RESEND_VERIFICATION_EMAIL_ERROR =
  'RESEND_VERIFICATION_EMAIL_ERROR';
export const MARK_NOTIFICATIONS_AS_SEEN = 'MARK_NOTIFICATIONS_AS_SEEN';
export const MARK_NOTIFICATIONS_AS_SEEN_SUCCESS =
  'MARK_NOTIFICATIONS_AS_SEEN_SUCCESS';

// TASKS
export const GET_TASKS = 'GET_TASKS';
export const GET_TASKS_ADMIN = 'GET_TASKS_ADMIN';
export const GET_TASKS_SUCCESS = 'GET_TASKS_SUCCESS';
export const GET_TASK_URL = 'GET_TASK_URL';
export const GET_TASK_URL_SUCCESS = 'GET_TASK_URL_SUCCESS';
export const GET_TASKS_ERROR = 'GET_TASKS_ERROR';
export const ADD_TASK = 'ADD_TASK';
export const ADD_TASK_SUCCESS = 'ADD_TASK_SUCCESS';
export const ADD_TASK_ERROR = 'ADD_TASK_ERROR';
export const UPDATE_TASK = 'UPDATE_TASK';
export const UPDATE_TASK_SUCCESS = 'UPDATE_TASK_SUCCESS';
export const UPDATE_TASK_ERROR = 'UPDATE_TASK_ERROR';
export const DELETE_TASK = 'DELETE_TASK';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DOWNLOAD_TASK = 'DOWNLOAD_TASK';
export const DOWNLOAD_TASK_SUCCESS = 'DOWNLOAD_TASK_SUCCESS';
export const DOWNLOAD_TASK_CLEAR = 'DOWNLOAD_TASK_CLEAR';
export const UPDATE_TASK_STATUS = 'UPDATE_TASK_STATUS';
export const UPDATE_TASK_STATUS_SUCCESS = 'UPDATE_TASK_STATUS_SUCCESS';
// PAYMENTS
export const PAYMENT = 'PAYMENT';
export const PAYMENT_SUCCESS = 'PAYMENT_SUCCESS';
export const PAYMENT_ERROR = 'PAYMENT_ERROR';
export const GET_PAYMENT_METHODS = 'GET_PAYMENT_METHODS';
export const GET_PAYMENT_METHODS_SUCCESS = 'GET_PAYMENT_METHODS_SUCCESS';
export const GET_PAYMENT_METHODS_ERROR = 'GET_PAYMENT_METHODS_ERROR';
// ADMIN
export const ADMIN_SUMMARY = 'ADMIN_SUMMARY';
export const ADMIN_SUMMARY_SUCCESS = 'ADMIN_SUMMARY_SUCCESS';
export const ALL_PUBLISHERS = 'ALL_PUBLISHERS';
export const ALL_PUBLISHERS_SUCCESS = 'ALL_PUBLISHERS_SUCCESS';
export const DELETE_PUBLISHER = 'DELETE_PUBLISHER';
export const DELETE_PUBLISHER_ERROR = 'DELETE_PUBLISHER_ERROR';
export const DELETE_PUBLISHER_SUCCESS = 'DELETE_PUBLISHER_SUCCESS';
export const RESTORE_PUBLISHER = 'RESTORE_PUBLISHER';
export const RESTORE_PUBLISHER_ERROR = 'RESTORE_PUBLISHER_ERROR';
export const RESTORE_PUBLISHER_SUCCESS = 'RESTORE_PUBLISHER_SUCCESS';
export const TEMPLATES_USAGE_REPORT = 'TEMPLATES_USAGE_REPORT';
export const TEMPLATES_USAGE_REPORT_SUCCESS = 'TEMPLATES_USAGE_REPORT_SUCCESS';
export const READER_LOGS_REPORT = 'READER_LOGS_REPORT';
export const READER_LOGS_REPORT_SUCCESS = 'READER_LOGS_REPORT_SUCCESS';
export const PUBLISHERS_REGISTRATION_REPORT = 'PUBLISHERS_REGISTRATION_REPORT';
export const PUBLISHERS_REGISTRATION_REPORT_SUCCESS =
  'PUBLISHERS_REGISTRATION_REPORT_SUCCESS';
export const ACCREDITATION_CREATION_REPORT = 'ACCREDITATION_CREATION_REPORT';
export const ACCREDITATION_CREATION_REPORT_SUCCESS =
  'ACCREDITATION_CREATION_REPORT_SUCCESS';
export const GET_USER_TRANSACTION_HISTORY = 'GET_USER_TRANSACTION_HISTORY';
export const GET_USER_TRANSACTION_HISTORY_SUCCESS =
  'GET_USER_TRANSACTION_HISTORY_SUCCESS';
export const CLEAR_TRANSACTION_HISTORY = 'CLEAR_TRANSACTION_HISTORY';

/* MENU */
export const MENU_SET_CLASSNAMES = 'MENU_SET_CLASSNAMES';
export const MENU_CONTAINER_ADD_CLASSNAME = 'MENU_CONTAINER_ADD_CLASSNAME';
export const MENU_CLICK_MOBILE_MENU = 'MENU_CLICK_MOBILE_MENU';
export const MENU_CHANGE_DEFAULT_CLASSES = 'MENU_CHANGE_DEFAULT_CLASSES';
export const MENU_CHANGE_HAS_SUB_ITEM_STATUS =
  'MENU_CHANGE_HAS_SUB_ITEM_STATUS';

const { gql } = require('@apollo/client');

const GET_PAYMENT_METHODS_QUERY = gql(`
  query{
    getPaymentMethods {
      last4, id, brand, exp_month, exp_year
    }
  }
`);

const PAYMENT_QUERY = gql(`
  mutation($paymentMethodId: String!, $uuid: String!, $renew: Boolean!){
    payment(payment_method_id: $paymentMethodId, uuid: $uuid, renew: $renew) {
      status, message
    }
  }
`);

module.exports = {
  GET_PAYMENT_METHODS_QUERY,
  PAYMENT_QUERY,
};

/* eslint-disable import/prefer-default-export */
import {
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_ERROR,
  GET_PAYMENT_METHODS_SUCCESS,
  PAYMENT,
  PAYMENT_ERROR,
  PAYMENT_SUCCESS,
} from 'redux/contants';

export const payment = (args, history) => ({
  type: PAYMENT,
  payload: { args, history },
});
export const paymentSuccess = () => ({
  type: PAYMENT_SUCCESS,
  payload: {},
});
export const paymentError = () => ({
  type: PAYMENT_ERROR,
  payload: {},
});

export const getPaymentMethods = () => ({
  type: GET_PAYMENT_METHODS,
  payload: {},
});
export const getPaymentMethodsSuccess = (data) => ({
  type: GET_PAYMENT_METHODS_SUCCESS,
  payload: data,
});
export const getPaymentMethodsError = () => ({
  type: GET_PAYMENT_METHODS_ERROR,
  payload: {},
});

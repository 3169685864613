import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REGISTER_USER_ERROR,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_ERROR,
  RESET_PASSWORD,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_ERROR,
  READ_ACCREDITATION,
  READ_ACCREDITATION_SUCCESS,
  READ_ACCREDITATION_ERROR,
  VERIFY_RECAPTCHA,
  VERIFY_RECAPTCHA_SUCCESS,
  VERIFY_RECAPTCHA_ERROR,
  CREATE_READER_LOG,
  CREATE_READER_LOG_SUCCESS,
  SEND_RECOVERY_CODE,
  SEND_RECOVERY_CODE_SUCCESS,
  VERIFY_RECOVERY_CODE,
  VERIFY_RECOVERY_CODE_SUCCESS,
  VERIFY_RECOVERY_CODE_ERROR,
  VERIFY_USER,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERROR,
  GET_ACCREDITATION_FILE,
  GET_ACCREDITATION_FILE_SUCCESS,
  GET_ACCREDITATION_FILE_ERROR,
  CREATE_ISSUE_REPORT,
  CREATE_ISSUE_REPORT_SUCCESS,
  READ_ACCREDITATION_LETTER,
  READ_ACCREDITATION_LETTER_SUCCESS,
  READ_ACCREDITATION_LETTER_ERROR,
  CLEAR_ACCREDITATION_LETTER,
} from 'redux/contants';

export const verifyReCaptcha = (args) => ({
  type: VERIFY_RECAPTCHA,
  payload: { args },
});
export const verifyReCaptchaSuccess = () => ({
  type: VERIFY_RECAPTCHA_SUCCESS,
  payload: {},
});
export const verifyReCaptchaError = () => ({
  type: VERIFY_RECAPTCHA_ERROR,
  payload: {},
});
export const verifyUser = (args) => ({
  type: VERIFY_USER,
  payload: { args },
});
export const verifyUserSuccess = () => ({
  type: VERIFY_USER_SUCCESS,
  payload: {},
});
export const verifyUserError = () => ({
  type: VERIFY_USER_ERROR,
  payload: {},
});
export const readAccreditation = (args) => ({
  type: READ_ACCREDITATION,
  payload: { args },
});
export const readAccreditationSuccess = (accreditation) => ({
  type: READ_ACCREDITATION_SUCCESS,
  payload: { accreditation },
});
export const readAccreditationError = (error) => ({
  type: READ_ACCREDITATION_ERROR,
  payload: { error },
});
export const readAccreditationLetter = (args) => ({
  type: READ_ACCREDITATION_LETTER,
  payload: args,
});
export const readAccreditationLetterSuccess = (file) => ({
  type: READ_ACCREDITATION_LETTER_SUCCESS,
  payload: file,
});
export const readAccreditationLetterError = () => ({
  type: READ_ACCREDITATION_LETTER_ERROR,
  payload: {},
});
export const createReaderLog = (args) => ({
  type: CREATE_READER_LOG,
  payload: { args },
});
export const createReaderLogSuccess = () => ({
  type: CREATE_READER_LOG_SUCCESS,
  payload: {},
});

export const createIssueReport = (args) => ({
  type: CREATE_ISSUE_REPORT,
  payload: { args },
});
export const createIssueReportSuccess = () => ({
  type: CREATE_ISSUE_REPORT_SUCCESS,
  payload: {},
});

export const loginUser = (user, history) => ({
  type: LOGIN_USER,
  payload: { user, history },
});
export const loginUserSuccess = (user) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
});
export const loginUserError = (message) => ({
  type: LOGIN_USER_ERROR,
  payload: { message },
});

export const getAccreditationFile = (args) => ({
  type: GET_ACCREDITATION_FILE,
  payload: args,
});
export const getAccreditationFileSuccess = (file) => ({
  type: GET_ACCREDITATION_FILE_SUCCESS,
  payload: file,
});
export const getAccreditationFileError = () => ({
  type: GET_ACCREDITATION_FILE_ERROR,
  payload: {},
});
export const clearAccreditationLetter = () => ({
  type: CLEAR_ACCREDITATION_LETTER,
  payload: {},
});

export const forgotPassword = (forgotUserMail, history) => ({
  type: FORGOT_PASSWORD,
  payload: { forgotUserMail, history },
});
export const forgotPasswordSuccess = (forgotUserMail) => ({
  type: FORGOT_PASSWORD_SUCCESS,
  payload: forgotUserMail,
});
export const forgotPasswordError = (message) => ({
  type: FORGOT_PASSWORD_ERROR,
  payload: { message },
});

export const sendRecoveryCode = (args) => ({
  type: SEND_RECOVERY_CODE,
  payload: { args },
});

export const sendRecoveryCodeSuccess = () => ({
  type: SEND_RECOVERY_CODE_SUCCESS,
  payload: {},
});

export const verifyRecoveryCode = (args) => ({
  type: VERIFY_RECOVERY_CODE,
  payload: { args },
});

export const verifyRecoveryCodeSuccess = () => ({
  type: VERIFY_RECOVERY_CODE_SUCCESS,
  payload: {},
});

export const verifyRecoveryCodeError = () => ({
  type: VERIFY_RECOVERY_CODE_ERROR,
  payload: {},
});

export const resetPassword = (args) => ({
  type: RESET_PASSWORD,
  payload: { args },
});
export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
  payload: {},
});
export const resetPasswordError = (message) => ({
  type: RESET_PASSWORD_ERROR,
  payload: { message },
});

export const registerUser = (user, history) => ({
  type: REGISTER_USER,
  payload: { user, history },
});
export const registerUserSuccess = () => ({
  type: REGISTER_USER_SUCCESS,
  payload: {},
});
export const registerUserError = () => ({
  type: REGISTER_USER_ERROR,
  payload: {},
});
export const logoutUser = (history) => ({
  type: LOGOUT_USER,
  payload: { history },
});

import { combineReducers } from 'redux';
import settings from './settings/reducer';
import menu from './menu/reducer';
import admin from './admin/reducer';
import authUser from './auth/reducer';
import userInfo from './user/reducer';
import tasks from './tasks/reducer';
import accreditations from './accreditations/reducer';
import payments from './payments/reducer';

const reducers = combineReducers({
  menu,
  settings,
  authUser,
  userInfo,
  admin,
  tasks,
  accreditations,
  payments,
});

export default reducers;

/* eslint-disable import/prefer-default-export */
import {
  ALL_ACCREDITATIONS,
  ALL_ACCREDITATIONS_SUCCESS,
  DELETE_ACCREDITATION,
  DELETE_ACCREDITATION_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  ACCREDITATIONS_SUMMARY,
  ACCREDITATIONS_SUMMARY_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATIONS_AS_SEEN,
  MARK_NOTIFICATIONS_AS_SEEN_SUCCESS,
  POSTPONE_ACCREDITATION_EXPIRATION,
  RESEND_VERIFICATION_EMAIL,
  RESEND_VERIFICATION_EMAIL_ERROR,
  RESEND_VERIFICATION_EMAIL_SUCCESS,
  RESTORE_ACCREDITATION,
  RESTORE_ACCREDITATION_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PERSONAL_INFORMATION,
  UPDATE_PERSONAL_INFORMATION_SUCCESS,
  USER_SUMMARY,
  USER_SUMMARY_SUCCESS,
} from 'redux/contants';

// Actions are sorted in a given section from the most common to the least common.

// User actions
export const userSummary = () => ({
  type: USER_SUMMARY,
  payload: {},
});

export const userSummarySuccess = (summary) => ({
  type: USER_SUMMARY_SUCCESS,
  payload: summary,
});

export const getNotifications = () => ({
  type: GET_NOTIFICATIONS,
  payload: {},
});

export const getNotificationsSuccess = (notifications) => ({
  type: GET_NOTIFICATIONS_SUCCESS,
  payload: notifications,
});

export const resendVerificationEmail = (args) => ({
  type: RESEND_VERIFICATION_EMAIL,
  payload: { args },
});

export const resendVerificationEmailSuccess = () => ({
  type: RESEND_VERIFICATION_EMAIL_SUCCESS,
  payload: {},
});

export const resendVerificationEmailError = () => ({
  type: RESEND_VERIFICATION_EMAIL_ERROR,
  payload: {},
});

export const markNotificationsAsSeen = () => ({
  type: MARK_NOTIFICATIONS_AS_SEEN,
  payload: {},
});

export const markNotificationsAsSeenSuccess = (notifications) => ({
  type: MARK_NOTIFICATIONS_AS_SEEN_SUCCESS,
  payload: notifications,
});

export const updatePersonalInformation = (user, history) => ({
  type: UPDATE_PERSONAL_INFORMATION,
  payload: { user, history },
});

export const updatePersonalInformationSuccess = (user) => ({
  type: UPDATE_PERSONAL_INFORMATION_SUCCESS,
  payload: user,
});

export const updatePassword = (args) => ({
  type: UPDATE_PASSWORD,
  payload: { args },
});

export const updatePasswordSuccess = () => ({
  type: UPDATE_PASSWORD_SUCCESS,
  payload: {},
});

export const updatePasswordError = () => ({
  type: UPDATE_PASSWORD_ERROR,
  payload: {},
});

export const deleteUser = (args, history) => ({
  type: DELETE_USER,
  payload: { args, history },
});

export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
  payload: {},
});

export const deleteUserError = () => ({
  type: DELETE_USER_ERROR,
  payload: {},
});

// Accreditation actions
export const accreditationsSummary = () => ({
  type: ACCREDITATIONS_SUMMARY,
  payload: {},
});

export const accreditationsSummarySuccess = (summary) => ({
  type: ACCREDITATIONS_SUMMARY_SUCCESS,
  payload: summary,
});

export const allAccreditations = (args) => ({
  type: ALL_ACCREDITATIONS,
  payload: { args },
});

export const allAccreditationsSuccess = (accreditations) => ({
  type: ALL_ACCREDITATIONS_SUCCESS,
  payload: accreditations,
});

export const postponeAccreditationExpiration = (args) => ({
  type: POSTPONE_ACCREDITATION_EXPIRATION,
  payload: { args },
});

export const deleteAccreditation = (args) => ({
  type: DELETE_ACCREDITATION,
  payload: { args },
});

export const restoreAccreditation = (args) => ({
  type: RESTORE_ACCREDITATION,
  payload: { args },
});

export const deleteAccreditationSuccess = () => ({
  type: DELETE_ACCREDITATION_SUCCESS,
  payload: {},
});

export const restoreAccreditationSuccess = () => ({
  type: RESTORE_ACCREDITATION_SUCCESS,
  payload: {},
});

import {
  ALL_ACCREDITATIONS,
  ALL_ACCREDITATIONS_SUCCESS,
  DELETE_ACCREDITATION,
  DELETE_ACCREDITATION_SUCCESS,
  DELETE_USER,
  DELETE_USER_ERROR,
  DELETE_USER_SUCCESS,
  ACCREDITATIONS_SUMMARY,
  ACCREDITATIONS_SUMMARY_SUCCESS,
  GET_NOTIFICATIONS,
  GET_NOTIFICATIONS_SUCCESS,
  MARK_NOTIFICATIONS_AS_SEEN,
  MARK_NOTIFICATIONS_AS_SEEN_SUCCESS,
  POSTPONE_ACCREDITATION_EXPIRATION,
  RESEND_VERIFICATION_EMAIL,
  RESEND_VERIFICATION_EMAIL_ERROR,
  RESEND_VERIFICATION_EMAIL_SUCCESS,
  RESTORE_ACCREDITATION,
  RESTORE_ACCREDITATION_SUCCESS,
  UPDATE_PASSWORD,
  UPDATE_PASSWORD_ERROR,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PERSONAL_INFORMATION,
  UPDATE_PERSONAL_INFORMATION_SUCCESS,
  USER_SUMMARY,
  USER_SUMMARY_SUCCESS,
} from 'redux/contants';

const INIT_STATE = {
  loading: false,
  error: '',
  accreditations: null,
  accreditationsSummary: null,
  userSummary: null,
  notifications: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_PERSONAL_INFORMATION:
      return { ...state, loading: true, error: '' };
    case UPDATE_PERSONAL_INFORMATION_SUCCESS:
      return { ...state, loading: false, error: '' };
    case RESEND_VERIFICATION_EMAIL:
      return { ...state, loading: true, error: '' };
    case RESEND_VERIFICATION_EMAIL_SUCCESS:
      return { ...state, loading: false, error: '' };
    case RESEND_VERIFICATION_EMAIL_ERROR:
      return { ...state, loading: false, error: '' };
    case UPDATE_PASSWORD:
      return { ...state, loading: true, error: '' };
    case UPDATE_PASSWORD_SUCCESS:
      return { ...state, loading: false, error: '' };
    case UPDATE_PASSWORD_ERROR:
      return { ...state, loading: false, error: '' };
    case DELETE_USER:
      return { ...state, loading: true, error: '' };
    case DELETE_USER_SUCCESS:
      return { ...state, loading: true, error: '' };
    case DELETE_USER_ERROR:
      return { ...state, loading: false, error: '' };
    case ALL_ACCREDITATIONS:
      return { ...state, loading: true, error: '', accreditations: null };
    case POSTPONE_ACCREDITATION_EXPIRATION:
      return { ...state, loading: true, error: '' };
    case DELETE_ACCREDITATION:
      return { ...state, loading: true, error: '' };
    case DELETE_ACCREDITATION_SUCCESS:
      return { ...state, loading: true, error: '' };
    case RESTORE_ACCREDITATION:
      return { ...state, loading: true, error: '' };
    case RESTORE_ACCREDITATION_SUCCESS:
      return { ...state, loading: true, error: '' };
    case ALL_ACCREDITATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        accreditations: action.payload,
      };
    case ACCREDITATIONS_SUMMARY:
      return { ...state, error: '' };
    case ACCREDITATIONS_SUMMARY_SUCCESS:
      return {
        ...state,
        error: '',
        accreditationsSummary: action.payload,
      };
    case GET_NOTIFICATIONS:
      return { ...state, loading: true, error: '' };
    case GET_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        notifications: action.payload,
      };
    case MARK_NOTIFICATIONS_AS_SEEN:
      return { ...state, loading: true, error: '' };
    case MARK_NOTIFICATIONS_AS_SEEN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        notifications: action.payload,
      };
    case USER_SUMMARY:
      return { ...state, loading: true, error: '' };
    case USER_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        userSummary: action.payload,
      };
    default:
      return { ...state };
  }
};

/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
import { apolloClient } from 'redux/sagas';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import { handleErrorMessage, handleSuccessMessage } from 'helpers/Utils';
import {
  ADMIN_SUMMARY,
  ALL_PUBLISHERS,
  DELETE_PUBLISHER,
  TEMPLATES_USAGE_REPORT,
  RESTORE_PUBLISHER,
  READER_LOGS_REPORT,
  PUBLISHERS_REGISTRATION_REPORT,
  ACCREDITATION_CREATION_REPORT,
} from 'redux/contants';
import {
  adminSummarySuccess,
  allPublishersSuccess,
  deletePublisherSuccess,
  getAccreditationsCreationReportSuccess,
  getPublishersRegistrationReportSuccess,
  getReaderLogsReportSuccess,
  getTemplatesUsageReportSuccess,
  restorePublisherSuccess,
} from 'redux/actions';
import {
  ADMIN_SUMMARY_QUERY,
  ALL_PUBLISHERS_QUERY,
  DELETE_PUBLISHER_QUERY,
  TEMPLATES_USAGE_REPORT_QUERY,
  RESTORE_PUBLISHER_QUERY,
  READER_LOGS_REPORT_QUERY,
  PUBLISHERS_REGISTRATION_REPORT_QUERY,
  ACCREDITATIONS_CREATION_REPORT_QUERY,
} from './queries';

export function* watchAdminSummary() {
  yield takeLatest(ADMIN_SUMMARY, adminSummaryGenerator);
}
const ADMIN_SUMMARY_API = async () => {
  return apolloClient.query({
    query: ADMIN_SUMMARY_QUERY,
    variables: {},
  });
};
function* adminSummaryGenerator() {
  try {
    const {
      data: { adminSummary },
    } = yield call(ADMIN_SUMMARY_API);
    yield put(adminSummarySuccess(adminSummary));
  } catch (e) {
    handleErrorMessage(e, 'Admin Summary Error', null);
  }
}

export function* watchTemplatesUsageReport() {
  yield takeLatest(TEMPLATES_USAGE_REPORT, templatesUsageReportGenerator);
}
const TEMPLATES_USAGE_REPORT_API = async (args) => {
  return apolloClient.query({
    query: TEMPLATES_USAGE_REPORT_QUERY,
    variables: {
      createdAtStart: args.createdAtStart,
      createdAtEnd: args.createdAtEnd,
    },
  });
};
function* templatesUsageReportGenerator({ payload }) {
  try {
    const {
      data: { templatesUsageReport },
    } = yield call(TEMPLATES_USAGE_REPORT_API, payload.args);
    yield put(getTemplatesUsageReportSuccess(templatesUsageReport));
  } catch (e) {
    handleErrorMessage(e, 'Templates Usage Report Error', null);
  }
}

export function* watchReaderLogsReport() {
  yield takeLatest(READER_LOGS_REPORT, readerLogsReportGenerator);
}
const READER_LOGS_REPORT_API = async (args) => {
  return apolloClient.query({
    query: READER_LOGS_REPORT_QUERY,
    variables: {
      createdAtStart: args?.createdAtStart,
      createdAtEnd: args?.createdAtEnd,
    },
  });
};
function* readerLogsReportGenerator({ payload }) {
  try {
    const {
      data: { readerLogsReport },
    } = yield call(READER_LOGS_REPORT_API, payload.args);
    yield put(getReaderLogsReportSuccess(readerLogsReport));
  } catch (e) {
    handleErrorMessage(e, 'Reader Logs Report Error', null);
  }
}

export function* watchPublishersRegistrationReport() {
  yield takeLatest(
    PUBLISHERS_REGISTRATION_REPORT,
    publishersRegistrationReportGenerator
  );
}
const PUBLISHERS_REGISTRATION_REPORT_API = async (args) => {
  return apolloClient.query({
    query: PUBLISHERS_REGISTRATION_REPORT_QUERY,
    variables: {
      createdAtStart: args?.createdAtStart,
      createdAtEnd: args?.createdAtEnd,
    },
  });
};
function* publishersRegistrationReportGenerator({ payload }) {
  try {
    const {
      data: { publishersRegistrationReport },
    } = yield call(PUBLISHERS_REGISTRATION_REPORT_API, payload.args);
    yield put(
      getPublishersRegistrationReportSuccess(publishersRegistrationReport)
    );
  } catch (e) {
    handleErrorMessage(e, 'Publishers Registration Report Error', null);
  }
}

export function* watchAccreditationsCreationReport() {
  yield takeLatest(
    ACCREDITATION_CREATION_REPORT,
    accreditationsCreationReportGenerator
  );
}
const ACCREDITATIONS_CREATION_REPORT_API = async (args) => {
  return apolloClient.query({
    query: ACCREDITATIONS_CREATION_REPORT_QUERY,
    variables: {
      createdAtStart: args?.createdAtStart,
      createdAtEnd: args?.createdAtEnd,
    },
  });
};
function* accreditationsCreationReportGenerator({ payload }) {
  try {
    const {
      data: { accreditationsCreationReport },
    } = yield call(ACCREDITATIONS_CREATION_REPORT_API, payload.args);
    yield put(
      getAccreditationsCreationReportSuccess(accreditationsCreationReport)
    );
  } catch (e) {
    handleErrorMessage(e, 'Accreditations Creation Report Error', null);
  }
}

export function* watchAllPublishers() {
  yield takeLatest(ALL_PUBLISHERS, allPublishersGenerator);
}
const ALL_PUBLISHERS_API = async (args) => {
  return apolloClient.query({
    query: ALL_PUBLISHERS_QUERY,
    variables: {
      limit: args?.limit,
      page: args?.page,
      search: args?.search,
      orderByField: args?.orderByField,
      orderDirection: args?.orderDirection,
      paranoid: args?.paranoid,
    },
  });
};
function* allPublishersGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { allUsers },
    } = yield call(ALL_PUBLISHERS_API, args);
    yield put(allPublishersSuccess(allUsers));
  } catch (e) {
    handleErrorMessage(e, 'Error fetching publishers', null);
  }
}

export function* watchDeletePublisher() {
  yield takeLatest(DELETE_PUBLISHER, deletePublisherGenerator);
}
const DELETE_PUBLISHER_API = async (args) => {
  return apolloClient.mutate({
    mutation: DELETE_PUBLISHER_QUERY,
    variables: { deleteUserByIdId: args?.id },
  });
};
function* deletePublisherGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { deleteUserById },
    } = yield call(DELETE_PUBLISHER_API, args);
    handleSuccessMessage(deleteUserById, 'Account Deleted');
    const state = yield select();
    const publishers = state?.admin?.publishers;
    const updatedPublishers = publishers?.rows?.map((p) => {
      if (p.id !== args.id) return p;
      return {
        ...p,
        deleted_at: new Date().getTime().toString(),
      };
    });
    yield put(
      deletePublisherSuccess({
        ...publishers,
        rows: updatedPublishers,
      })
    );
  } catch (e) {
    handleErrorMessage(e, 'Error deleting publisher account', null);
  }
}

export function* watchRestorePublisher() {
  yield takeLatest(RESTORE_PUBLISHER, restorePublisherGenerator);
}
const RESTORE_PUBLISHER_API = async (args) => {
  return apolloClient.mutate({
    mutation: RESTORE_PUBLISHER_QUERY,
    variables: { restoreUserByIdId: args?.id },
  });
};
function* restorePublisherGenerator({ payload }) {
  const { args } = payload;
  try {
    const {
      data: { restoreUserById },
    } = yield call(RESTORE_PUBLISHER_API, args);
    handleSuccessMessage(restoreUserById, 'Account Restored');
    const state = yield select();
    const publishers = state?.admin?.publishers;
    const updatedPublishers = publishers?.rows?.map((p) => {
      if (p.id !== args.id) return p;
      return {
        ...p,
        deleted_at: null,
      };
    });
    yield put(
      restorePublisherSuccess({
        ...publishers,
        rows: updatedPublishers,
      })
    );
  } catch (e) {
    handleErrorMessage(e, 'Error restoring publisher account', null);
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchAdminSummary),
    fork(watchAllPublishers),
    fork(watchDeletePublisher),
    fork(watchRestorePublisher),
    fork(watchTemplatesUsageReport),
    fork(watchReaderLogsReport),
    fork(watchPublishersRegistrationReport),
    fork(watchAccreditationsCreationReport),
  ]);
}

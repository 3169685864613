/* eslint-disable import/prefer-default-export */

import {
  BULK_DELETE_ACCREDITATIONS,
  BULK_DELETE_ACCREDITATIONS_ERROR,
  BULK_DELETE_ACCREDITATIONS_SUCCESS,
  BULK_POSTPONE_EXPIRATION_ACCREDITATIONS,
  BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_ERROR,
  BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_SUCCESS,
  BULK_RESTORE_ACCREDITATIONS,
  BULK_RESTORE_ACCREDITATIONS_ERROR,
  BULK_RESTORE_ACCREDITATIONS_SUCCESS,
  CLEAR_ACCREDITATION,
  CREATE_ACCREDITATION,
  CREATE_ACCREDITATION_ERROR,
  CREATE_ACCREDITATION_SUCCESS,
  ACCREDITATION_ISSUE_REPORTS,
  ACCREDITATION_ISSUE_REPORTS_CLEAR,
  ACCREDITATION_ISSUE_REPORTS_SUCCESS,
  ACCREDITATION_READER_LOGS,
  ACCREDITATION_READER_LOGS_CLEAR,
  ACCREDITATION_READER_LOGS_SUCCESS,
  GET_ACCREDITATION_FILE_URL,
  GET_ACCREDITATION_FILE_URL_SUCCESS,
  APPROVE_ACCREDITATION,
  APPROVE_ACCREDITATION_SUCCESS,
  APPROVE_ACCREDITATION_ERROR,
  REJECT_ACCREDITATION,
  REJECT_ACCREDITATION_SUCCESS,
  REJECT_ACCREDITATION_ERROR,
  REVISE_ACCREDITATION_SUCCESS,
  REVISE_ACCREDITATION_ERROR,
  REVISE_ACCREDITATION,
  GET_ACCREDITATION,
  GET_ACCREDITATION_SUCCESS,
  GET_ACCREDITATION_ERROR,
  UPDATE_ACCREDITATION,
  UPDATE_ACCREDITATION_SUCCESS,
  UPDATE_ACCREDITATION_ERROR,
} from 'redux/contants';

export const createAccreditation = (accreditation, history) => ({
  type: CREATE_ACCREDITATION,
  payload: { accreditation, history },
});
export const createAccreditationSuccess = (accreditation) => ({
  type: CREATE_ACCREDITATION_SUCCESS,
  payload: accreditation,
});
export const createAccreditationError = () => ({
  type: CREATE_ACCREDITATION_ERROR,
  payload: {},
});

export const updateAccreditation = (accreditation, history) => ({
  type: UPDATE_ACCREDITATION,
  payload: { accreditation, history },
});
export const updateAccreditationSuccess = () => ({
  type: UPDATE_ACCREDITATION_SUCCESS,
  payload: {},
});
export const updateAccreditationError = () => ({
  type: UPDATE_ACCREDITATION_ERROR,
  payload: {},
});

export const getAccreditationFileUrl = () => ({
  type: GET_ACCREDITATION_FILE_URL,
  payload: {},
});
export const getAccreditationFileUrlSuccess = (accreditationFile) => ({
  type: GET_ACCREDITATION_FILE_URL_SUCCESS,
  payload: accreditationFile,
});

export const getAccreditationIssueReports = (args) => ({
  type: ACCREDITATION_ISSUE_REPORTS,
  payload: { args },
});
export const getAccreditationIssueReportsSuccess = (issueReports) => ({
  type: ACCREDITATION_ISSUE_REPORTS_SUCCESS,
  payload: issueReports,
});
export const clearAccreditationIssueReports = () => ({
  type: ACCREDITATION_ISSUE_REPORTS_CLEAR,
  payload: {},
});

export const getAccreditationReaderLogs = (args) => ({
  type: ACCREDITATION_READER_LOGS,
  payload: { args },
});
export const getAccreditationReaderLogsSuccess = (readerLogs) => ({
  type: ACCREDITATION_READER_LOGS_SUCCESS,
  payload: readerLogs,
});
export const clearAccreditationReaderLogs = () => ({
  type: ACCREDITATION_READER_LOGS_CLEAR,
  payload: {},
});

export const bulkDeleteAccreditations = (args) => ({
  type: BULK_DELETE_ACCREDITATIONS,
  payload: { args },
});
export const bulkDeleteAccreditationsSuccess = () => ({
  type: BULK_DELETE_ACCREDITATIONS_SUCCESS,
  payload: {},
});
export const bulkDeleteAccreditationsError = () => ({
  type: BULK_DELETE_ACCREDITATIONS_ERROR,
  payload: {},
});

export const bulkRestoreAccreditations = (args) => ({
  type: BULK_RESTORE_ACCREDITATIONS,
  payload: { args },
});
export const bulkRestoreAccreditationsSuccess = () => ({
  type: BULK_RESTORE_ACCREDITATIONS_SUCCESS,
  payload: {},
});
export const bulkRestoreAccreditationsError = () => ({
  type: BULK_RESTORE_ACCREDITATIONS_ERROR,
  payload: {},
});

export const bulkPostponeExpirationAccreditations = (args) => ({
  type: BULK_POSTPONE_EXPIRATION_ACCREDITATIONS,
  payload: { args },
});
export const bulkPostponeExpirationAccreditationsSuccess = () => ({
  type: BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_SUCCESS,
  payload: {},
});
export const bulkPostponeExpirationAccreditationsError = () => ({
  type: BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_ERROR,
  payload: {},
});

export const approveAccreditation = (args) => ({
  type: APPROVE_ACCREDITATION,
  payload: { args },
});
export const approveAccreditationSuccess = () => ({
  type: APPROVE_ACCREDITATION_SUCCESS,
  payload: {},
});
export const approveAccreditationError = () => ({
  type: APPROVE_ACCREDITATION_ERROR,
  payload: {},
});

export const rejectAccreditation = (args) => ({
  type: REJECT_ACCREDITATION,
  payload: { args },
});
export const rejectAccreditationSuccess = () => ({
  type: REJECT_ACCREDITATION_SUCCESS,
  payload: {},
});
export const rejectAccreditationError = () => ({
  type: REJECT_ACCREDITATION_ERROR,
  payload: {},
});

export const reviseAccreditation = (args) => ({
  type: REVISE_ACCREDITATION,
  payload: { args },
});
export const reviseAccreditationSuccess = () => ({
  type: REVISE_ACCREDITATION_SUCCESS,
  payload: {},
});
export const reviseAccreditationError = () => ({
  type: REVISE_ACCREDITATION_ERROR,
  payload: {},
});

export const getAccreditation = (args) => ({
  type: GET_ACCREDITATION,
  payload: { args },
});
export const getAccreditationSuccess = (accreditation) => ({
  type: GET_ACCREDITATION_SUCCESS,
  payload: accreditation,
});
export const getAccreditationError = () => ({
  type: GET_ACCREDITATION_ERROR,
  payload: {},
});

export const clearAccreditation = () => ({
  type: CLEAR_ACCREDITATION,
  payload: {},
});

import {
  ADMIN_SUMMARY,
  ADMIN_SUMMARY_SUCCESS,
  ALL_PUBLISHERS,
  ALL_PUBLISHERS_SUCCESS,
  DELETE_PUBLISHER,
  DELETE_PUBLISHER_ERROR,
  DELETE_PUBLISHER_SUCCESS,
  TEMPLATES_USAGE_REPORT,
  TEMPLATES_USAGE_REPORT_SUCCESS,
  RESTORE_PUBLISHER,
  RESTORE_PUBLISHER_ERROR,
  RESTORE_PUBLISHER_SUCCESS,
  READER_LOGS_REPORT,
  READER_LOGS_REPORT_SUCCESS,
  PUBLISHERS_REGISTRATION_REPORT,
  PUBLISHERS_REGISTRATION_REPORT_SUCCESS,
  ACCREDITATION_CREATION_REPORT,
  ACCREDITATION_CREATION_REPORT_SUCCESS,
} from 'redux/contants';

const INIT_STATE = {
  loading: false,
  error: null,
  adminDashboardSummary: null,
  publishers: null,
  templatesUsageReport: null,
  readerLogsReport: null,
  publishersRegistrationReport: null,
  entriesCreationReport: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADMIN_SUMMARY:
      return { ...state, loading: true, error: null };
    case ADMIN_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        adminDashboardSummary: action.payload,
      };
    case TEMPLATES_USAGE_REPORT:
      return { ...state, loading: true, error: null };
    case TEMPLATES_USAGE_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        templatesUsageReport: action.payload,
      };
    case READER_LOGS_REPORT:
      return { ...state, loading: true, error: null };
    case READER_LOGS_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        readerLogsReport: action.payload,
      };
    case PUBLISHERS_REGISTRATION_REPORT:
      return { ...state, loading: true, error: null };
    case PUBLISHERS_REGISTRATION_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        publishersRegistrationReport: action.payload,
      };
    case ACCREDITATION_CREATION_REPORT:
      return { ...state, loading: true, error: null };
    case ACCREDITATION_CREATION_REPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        entriesCreationReport: action.payload,
      };
    case ALL_PUBLISHERS:
      return { ...state, loading: true, error: null };
    case ALL_PUBLISHERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        publishers: action.payload,
      };
    case DELETE_PUBLISHER:
      return { ...state, loading: true, error: null };
    case DELETE_PUBLISHER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        publishers: action.payload,
      };
    case DELETE_PUBLISHER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case RESTORE_PUBLISHER:
      return { ...state, loading: true, error: null };
    case RESTORE_PUBLISHER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        publishers: action.payload,
      };
    case RESTORE_PUBLISHER_ERROR:
      return {
        ...state,
        loading: false,
        error: null,
      };
    default:
      return { ...state };
  }
};

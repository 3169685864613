const { gql } = require('@apollo/client');

// TODO: Verify if everything here is in the proper file (non-auth section?)

const CREATE_ACCREDITATION_QUERY = gql(`
  mutation(
    $nickname: String!, 
    $salt: String!, 
    $url: String!, 
    $jsonPayload: String!, 
    $type: String!, 
    $encryptionVersion: Int!, 
    $metadata: String!,
    $tasks: [TaskInput!]!){
      createAccreditation(
        nickname: $nickname, 
        salt: $salt, 
        url: $url, 
        json_payload: $jsonPayload, 
        type: $type, 
        encryption_version: $encryptionVersion, 
        tasks: $tasks, 
        metadata: $metadata
      ) {
        id, nickname, url, salt, json_payload, metadata, uuid
      }
    }
`);

const UPDATE_ACCREDITATION_QUERY = gql(`
  mutation(
    $id: Int!
    $tasks: [TaskInput!]!){
      updateAccreditation(
        id: $id,
        tasks: $tasks
      ) {
        message, status
      }
    }
`);

const GET_ACCREDITATION_FILE_URL_QUERY = gql(`
  query{
    getAccreditationFileUrl {
      url, filename
    }
  }
`);

const ACCREDITATION_ISSUE_REPORTS_QUERY = gql(`
  query($accreditationId: Int){
    accreditationIssueReports(accreditationId: $accreditationId) {
      id, name, email, type, message, location, ip, created_at
    }
  }
`);

const ACCREDITATION_READER_LOGS_QUERY = gql(`
  query($accreditationId: Int!){
    accreditationReaderLogs(accreditationId: $accreditationId) {
      id, created_at, location, result
    }
  }
`);

const BULK_DELETE_ACCREDITATIONS_QUERY = gql(`
  mutation($ids: [ID]!){
    bulkDeleteAccreditations(ids: $ids) {
      status, message
    }
  }
`);

const BULK_RESTORE_ACCREDITATIONS_QUERY = gql(`
  mutation($ids: [ID]!){
    bulkRestoreAccreditations(ids: $ids) {
      status, message
    }
  }
`);

const BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_QUERY = gql(`
  mutation($ids: [ID]!, $expiresAt: String!){
    bulkPostponeAccreditationExpiration(ids: $ids, expires_at: $expiresAt) {
      message, status
    }
  }
`);

const APPROVE_ACCREDITATION_QUERY = gql(`
  mutation($approveAccreditationId: ID!, $accreditationLetter: String!, $attachment: String!, $url: String!, $name: String!, $password: String!){
    approveAccreditation(id: $approveAccreditationId, accreditation_letter: $accreditationLetter, attachment: $attachment, url: $url, name: $name, password: $password) {
      status, message
    }
  }
`);

const REJECT_ACCREDITATION_QUERY = gql(`
  mutation($rejectAccreditationId: ID!, $comments: String!, $name: String!){
    rejectAccreditation(id: $rejectAccreditationId, comments: $comments, name: $name) {
      status, message
    }
  }
`);

const REVISE_ACCREDITATION_QUERY = gql(`
  mutation($reviseAccreditationId: ID!, $comments: String!, $name: String!){
    reviseAccreditation(id: $reviseAccreditationId, comments: $comments, name: $name) {
      status, message
    }
  }
`);

const GET_ACCREDITATION_QUERY = gql(`
  query($uuid: String!){
    getAccreditation(uuid: $uuid) {
      id, uuid, nickname, url, salt, status, encryption_version, type, tasks {
        slug, type, text, file_size, file_type, filename, filename_original
      }
    }
  }
`);

module.exports = {
  CREATE_ACCREDITATION_QUERY,
  GET_ACCREDITATION_FILE_URL_QUERY,
  ACCREDITATION_ISSUE_REPORTS_QUERY,
  ACCREDITATION_READER_LOGS_QUERY,
  BULK_DELETE_ACCREDITATIONS_QUERY,
  BULK_RESTORE_ACCREDITATIONS_QUERY,
  BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_QUERY,
  APPROVE_ACCREDITATION_QUERY,
  REJECT_ACCREDITATION_QUERY,
  REVISE_ACCREDITATION_QUERY,
  GET_ACCREDITATION_QUERY,
  UPDATE_ACCREDITATION_QUERY,
};

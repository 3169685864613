import {
  BULK_DELETE_ACCREDITATIONS,
  BULK_DELETE_ACCREDITATIONS_ERROR,
  BULK_DELETE_ACCREDITATIONS_SUCCESS,
  BULK_POSTPONE_EXPIRATION_ACCREDITATIONS,
  BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_ERROR,
  BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_SUCCESS,
  BULK_RESTORE_ACCREDITATIONS,
  BULK_RESTORE_ACCREDITATIONS_ERROR,
  BULK_RESTORE_ACCREDITATIONS_SUCCESS,
  CLEAR_ACCREDITATION,
  CREATE_ACCREDITATION,
  CREATE_ACCREDITATION_ERROR,
  CREATE_ACCREDITATION_SUCCESS,
  ACCREDITATION_ISSUE_REPORTS,
  ACCREDITATION_ISSUE_REPORTS_CLEAR,
  ACCREDITATION_ISSUE_REPORTS_SUCCESS,
  ACCREDITATION_READER_LOGS,
  ACCREDITATION_READER_LOGS_CLEAR,
  ACCREDITATION_READER_LOGS_SUCCESS,
  GET_ACCREDITATION_FILE_URL,
  GET_ACCREDITATION_FILE_URL_SUCCESS,
  APPROVE_ACCREDITATION,
  APPROVE_ACCREDITATION_SUCCESS,
  APPROVE_ACCREDITATION_ERROR,
  REJECT_ACCREDITATION,
  REJECT_ACCREDITATION_SUCCESS,
  REJECT_ACCREDITATION_ERROR,
  REVISE_ACCREDITATION,
  REVISE_ACCREDITATION_SUCCESS,
  REVISE_ACCREDITATION_ERROR,
  GET_ACCREDITATION,
  GET_ACCREDITATION_SUCCESS,
  GET_ACCREDITATION_ERROR,
  UPDATE_ACCREDITATION,
  UPDATE_ACCREDITATION_SUCCESS,
  UPDATE_ACCREDITATION_ERROR,
} from 'redux/contants';

const INIT_STATE = {
  accreditationFileUrl: null,
  accreditationIssueReports: null,
  accreditationReaderLogs: null,
  accreditation: null,
  loading: false,
  error: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CREATE_ACCREDITATION:
      return { ...state, loading: true, error: '', accreditation: null };
    case UPDATE_ACCREDITATION:
      return { ...state, loading: true, error: '' };
    case CLEAR_ACCREDITATION:
      return {
        ...state,
        loading: false,
        error: '',
        accreditation: null,
        accreditationFileUrl: null,
      };
    case CREATE_ACCREDITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        accreditation: action.payload,
      };
    case CREATE_ACCREDITATION_ERROR:
      return { ...state, loading: false, error: '' };
    case UPDATE_ACCREDITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case UPDATE_ACCREDITATION_ERROR:
      return { ...state, loading: false, error: '' };
    case GET_ACCREDITATION_FILE_URL:
      return { ...state, loading: true, error: '', accreditationFileUrl: null };
    case GET_ACCREDITATION_FILE_URL_SUCCESS:
      return {
        ...state,
        loading: true,
        error: '',
        accreditationFileUrl: action.payload,
      };
    case ACCREDITATION_ISSUE_REPORTS:
      return {
        ...state,
        loading: true,
        error: '',
        accreditationIssueReports: null,
      };
    case ACCREDITATION_ISSUE_REPORTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        accreditationIssueReports: action.payload,
      };
    case ACCREDITATION_ISSUE_REPORTS_CLEAR:
      return {
        ...state,
        loading: false,
        error: '',
        accreditationIssueReports: null,
      };
    case ACCREDITATION_READER_LOGS:
      return {
        ...state,
        loading: true,
        error: '',
        accreditationReaderLogs: null,
      };
    case ACCREDITATION_READER_LOGS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        accreditationReaderLogs: action.payload,
      };
    case ACCREDITATION_READER_LOGS_CLEAR:
      return {
        ...state,
        loading: false,
        error: '',
        accreditationReaderLogs: null,
      };
    case BULK_DELETE_ACCREDITATIONS:
      return { ...state, loading: true, error: '' };
    case BULK_DELETE_ACCREDITATIONS_SUCCESS:
      return { ...state, loading: false, error: '' };
    case BULK_DELETE_ACCREDITATIONS_ERROR:
      return { ...state, loading: false, error: '' };
    case APPROVE_ACCREDITATION:
      return { ...state, loading: true, error: '' };
    case APPROVE_ACCREDITATION_SUCCESS:
      return { ...state, loading: false, error: '' };
    case APPROVE_ACCREDITATION_ERROR:
      return { ...state, loading: false, error: '' };
    case REJECT_ACCREDITATION:
      return { ...state, loading: true, error: '' };
    case REJECT_ACCREDITATION_SUCCESS:
      return { ...state, loading: false, error: '' };
    case REJECT_ACCREDITATION_ERROR:
      return { ...state, loading: false, error: '' };
    case REVISE_ACCREDITATION:
      return { ...state, loading: true, error: '' };
    case REVISE_ACCREDITATION_SUCCESS:
      return { ...state, loading: false, error: '' };
    case REVISE_ACCREDITATION_ERROR:
      return { ...state, loading: false, error: '' };
    case GET_ACCREDITATION:
      return { ...state, loading: true, error: '', accreditation: null };
    case GET_ACCREDITATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: '',
        accreditation: action.payload,
      };
    case GET_ACCREDITATION_ERROR:
      return { ...state, loading: false, error: '', accreditation: null };
    case BULK_RESTORE_ACCREDITATIONS:
      return { ...state, loading: true, error: '' };
    case BULK_RESTORE_ACCREDITATIONS_SUCCESS:
      return { ...state, loading: false, error: '' };
    case BULK_RESTORE_ACCREDITATIONS_ERROR:
      return { ...state, loading: false, error: '' };
    case BULK_POSTPONE_EXPIRATION_ACCREDITATIONS:
      return { ...state, loading: true, error: '' };
    case BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_SUCCESS:
      return { ...state, loading: false, error: '' };
    case BULK_POSTPONE_EXPIRATION_ACCREDITATIONS_ERROR:
      return { ...state, loading: false, error: '' };
    default:
      return { ...state };
  }
};

const { gql } = require('@apollo/client');

const GET_TASKS_QUERY = gql(`
  query($limit: Int, $page: Int){
    getTasks(limit: $limit, page: $page) {
      page, allPages, count, rows {
        id, name, email, tasks {
          slug, id, file_size, file_type, type, filename, filename_original, created_at, updated_at, deleted_at
        }
      }
    }
  }`);

const GET_TASKS_ADMIN_QUERY = gql(`
  query($limit: Int, $page: Int){
    getTasksAdmin(limit: $limit, page: $page) {
      page, allPages, count, rows {
        id, name, email, tasks {
          slug, id, file_size, file_type, type, filename, filename_original, text, created_at, updated_at, deleted_at
        }
      }
    }
  }`);

const ADD_TASK_QUERY = gql(`
  mutation($limit: Int, $page: Int, $taskSlug: String!, $taskType: String!, $text: String, $filename: String, $filenameOriginal: String, $fileSize: Int, $fileType: String){
    addTask(limit: $limit, page: $page, slug: $taskSlug, task_type: $taskType, text: $text, filename: $filename, filename_original: $filenameOriginal, file_size: $fileSize, file_type: $fileType, ) {
      page, allPages, count, rows {
        id, name, email, tasks {
          slug, id, file_size, file_type, task_type, filename, filename_original, text, created_at, updated_at, deleted_at
        }
      }
    }
  }`);

const UPDATE_TASK_QUERY = gql(`
  mutation($limit: Int, $page: Int, $id: ID!, $taskSlug: String!, $taskType: String!, $text: String, $filename: String, $filenameOriginal: String, $fileSize: Int, $fileType: String){
    updateTasklimit(limit: $limit, page: $page, id: $id, slug: $taskSlug, task_type: $taskType, text: $text, filename: $filename, filename_original: $filenameOriginal, file_size: $fileSize, file_type: $fileType) {
      page, allPages, count, rows {
        id, name, email, tasks {
          slug, id, file_size, file_type, type, filename, filename_original, text, created_at, updated_at, deleted_at
        }
      }
    }
  }`);

const GET_TASK_URL_QUERY = gql(`
  query($type: String!){
    getTaskUrl(type: $type) {
      filename, url
    }
  }`);

const DOWNLOAD_TASK_QUERY = gql(`
  query($task_id: Int!){
    downloadTask(task_id: $task_id) {
      body, status, filename, filename_original
    }
  }`);

const DELETE_TASK_QUERY = gql(`
  mutation($limit: Int, $page: Int, $deleteTaskId: ID!){
    deleteTask(limit: $limit, page: $page, id: $deleteTaskId) {
      page, allPages, count, rows {
        id, name, email, tasks {
          slug, id, file_size, file_type, type, filename, filename_original, text, created_at, updated_at, deleted_at
        }
      }
    }
  }`);

const UPDATE_TASK_STATUS_QUERY = gql(`
  mutation($limit: Int, $page: Int){
    updateTaskStatus(limit: $limit, page: $page) {
      page, allPages, count, rows {
        id, name, email, tasks {
          slug, id, file_size, file_type, type, filename, filename_original, created_at, updated_at, deleted_at
        }
      }
    }
  }`);

module.exports = {
  GET_TASKS_QUERY,
  ADD_TASK_QUERY,
  GET_TASK_URL_QUERY,
  DELETE_TASK_QUERY,
  DOWNLOAD_TASK_QUERY,
  GET_TASKS_ADMIN_QUERY,
  UPDATE_TASK_STATUS_QUERY,
  UPDATE_TASK_QUERY,
};

/* eslint-disable no-unused-vars */
import { all } from 'redux-saga/effects';
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import Swal from 'sweetalert2';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import {
  getCurrentUser,
  handleErrorMessage,
  setCurrentUser,
} from 'helpers/Utils';
import authSagas from './auth/saga';
import accreditationsSagas from './accreditations/saga';
import userSagas from './user/saga';
import adminSagas from './admin/saga';
import docSagas from './tasks/saga';
import paymentsSagas from './payments/saga';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL,
});

const authLink = setContext((_, { headers }) => {
  const context = getCurrentUser();
  return {
    headers: {
      ...headers,
      authorization: context?.token ? `${context?.token}` : '',
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  let timerInterval;
  if (graphQLErrors) {
    if (graphQLErrors[0]?.extensions?.code === 'UNAUTHENTICATED') {
      Swal.fire({
        title: 'Session Expired',
        text: graphQLErrors[0]?.message,
        icon: 'error',
        showConfirmButton: false,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        willClose: () => {
          clearInterval(timerInterval);
        },
        showClass: {
          backdrop: 'swal2-noanimation',
          icon: '',
          popup: '',
        },
      }).then(() => {
        setCurrentUser(null);
        window.location.href = '/user/login';
      });
    }
  } else if (networkError)
    handleErrorMessage(
      'It seems like you are disconnected from the network.',
      'Network Failure',
      null
    );
});

export const apolloClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
    },
    query: {
      fetchPolicy: 'no-cache',
    },
    mutate: {
      fetchPolicy: 'no-cache',
    },
  },
});

export default function* rootSaga() {
  yield all([
    authSagas(),
    userSagas(),
    adminSagas(),
    accreditationsSagas(),
    docSagas(),
    paymentsSagas(),
  ]);
}

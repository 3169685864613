/* eslint-disable import/no-cycle */
/* eslint-disable camelcase */
/* eslint-disable prettier/prettier */
/* eslint-disable no-use-before-define */
import { apolloClient } from 'redux/sagas';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { handleErrorMessage, handleSuccessMessage, setCurrentUser, updateCurrentUser } from 'helpers/Utils';
import {
  allAccreditationsSuccess,
  deleteAccreditationSuccess,
  deleteUserSuccess,
  accreditationsSummarySuccess,
  getNotificationsSuccess,
  markNotificationsAsSeenSuccess,
  resendVerificationEmailError,
  resendVerificationEmailSuccess,
  restoreAccreditationSuccess,
  updatePasswordError,
  updatePasswordSuccess,
  updatePersonalInformationSuccess,
  userSummarySuccess
} from 'redux/actions';
import {
  ALL_ACCREDITATIONS,
  DELETE_ACCREDITATION,
  DELETE_USER,
  ACCREDITATIONS_SUMMARY,
  GET_NOTIFICATIONS,
  MARK_NOTIFICATIONS_AS_SEEN,
  POSTPONE_ACCREDITATION_EXPIRATION,
  RESEND_VERIFICATION_EMAIL,
  RESTORE_ACCREDITATION,
  UPDATE_PASSWORD,
  UPDATE_PERSONAL_INFORMATION,
  USER_SUMMARY
} from 'redux/contants';
import {
  ALL_ACCREDITATIONS_QUERY,
  DELETE_ACCREDITATION_QUERY,
  DELETE_USER_QUERY,
  ACCREDITATIONS_SUMMARY_QUERY,
  GET_NOTIFICATIONS_QUERY,
  MARK_NOTIFICATIONS_AS_SEEN_QUERY,
  POSTPONE_ACCREDITATION_EXPIRATION_QUERY,
  RESEND_VERIFICATION_EMAIL_QUERY,
  RESTORE_ACCREDITATION_QUERY,
  UPDATE_PASSWORD_QUERY,
  UPDATE_PERSONAL_INFORMATION_QUERY,
  USER_SUMMARY_QUERY
} from './queries';

export function* watchUserSummary() {
  yield takeLatest(USER_SUMMARY, userSummaryGenerator);
}
const USER_SUMMARY_API = async () => {
  return apolloClient.query({
    query: USER_SUMMARY_QUERY,
    variables: {}
  });
}
function* userSummaryGenerator() {
  try {
    const { data: { userSummary } } = yield call(USER_SUMMARY_API);
    yield put(userSummarySuccess(userSummary));
  } catch (e) {
    handleErrorMessage(e, 'User Summary Error', null);
  }
}

export function* watchGetNotifications() {
  yield takeLatest(GET_NOTIFICATIONS, getNotificationsGenerator);
}
const GET_NOTIFICATIONS_API = async () => {
  return apolloClient.query({
    query: GET_NOTIFICATIONS_QUERY,
    variables: {}
  });
}
function* getNotificationsGenerator() {
  try {
    const { data: { getNotifications } } = yield call(GET_NOTIFICATIONS_API);
    yield put(getNotificationsSuccess(getNotifications));
  } catch (e) {
    handleErrorMessage(e, 'Get Notifications Error', null);
  }
}

export function* watchMarkNotificationsAsSeen() {
  yield takeLatest(MARK_NOTIFICATIONS_AS_SEEN, markNotificationsAsSeenGenerator);
}
const MARK_NOTIFICATIONS_AS_SEEN_API = async () => {
  return apolloClient.query({
    query: MARK_NOTIFICATIONS_AS_SEEN_QUERY,
    variables: {}
  });
}
function* markNotificationsAsSeenGenerator() {
  try {
    const { data: { markNotificationsAsSeen } } = yield call(MARK_NOTIFICATIONS_AS_SEEN_API);
    yield put(markNotificationsAsSeenSuccess(markNotificationsAsSeen));
  } catch (e) {
    handleErrorMessage(e, 'Mark Notifications As Seen Error', null);
  }
}

export function* watchUpdatePersonalInformation() {
  yield takeEvery(UPDATE_PERSONAL_INFORMATION, updatePersonalInformationGenerator);
}
export const UPDATE_PERSONAL_INFORMATION_API = async ( user ) => {
  return apolloClient.mutate({
    mutation: UPDATE_PERSONAL_INFORMATION_QUERY,
    variables: { name: user.name, secondaryEmail: user.secondary_email }
  });
}
export function* updatePersonalInformationGenerator({ payload }) {
  const { user, history } = payload;
  try {
    const { data: { updatePersonalInformation }} = yield call(UPDATE_PERSONAL_INFORMATION_API, user);
    yield put(updatePersonalInformationSuccess(updatePersonalInformation));
    handleSuccessMessage('Your personal information has been updated.', 'Personal Information Updated');
    updateCurrentUser(updatePersonalInformation);
  } catch (e) {
    handleErrorMessage(e, 'Update Personal Information Error', history);
  }
}

export function* watchUpdatePassword() {
  yield takeEvery(UPDATE_PASSWORD, updatePasswordGenerator);
}
export const UPDATE_PASSWORD_API = async ( args ) => {
  return apolloClient.mutate({
    mutation: UPDATE_PASSWORD_QUERY,
    variables: { newPassword: args.new_password, currentPassword: args.current_password }
  });
}
export function* updatePasswordGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const { data: { updatePassword }} = yield call(UPDATE_PASSWORD_API, args);
    handleSuccessMessage(updatePassword, 'Password Updated');
    yield put(updatePasswordSuccess());
  } catch (e) {
    yield put(updatePasswordError());
    handleErrorMessage(e, 'Update Password Error', history);
  }
}

export function* watchDeleteUser() {
  yield takeEvery(DELETE_USER, deleteUserGenerator);
}
const DELETE_USER_API = async ( args ) => {
  return apolloClient.mutate({
    mutation: DELETE_USER_QUERY,
    variables: { password: args.password }
  });
}
function* deleteUserGenerator({ payload }) {
  const { args, history } = payload;
  try {
    const { data: { deleteUser }} = yield call(DELETE_USER_API, args);
    handleSuccessMessage(deleteUser, 'Account Deleted');
    yield put(deleteUserSuccess());
    setCurrentUser(null);
    history.push('/user/login');
  } catch (e) {
    yield put(deleteUserSuccess());
    handleErrorMessage(e, 'Delete Account Error', history);
  }
}

export function* watchAllAccreditations() {
  yield takeEvery(ALL_ACCREDITATIONS, allAccreditationsGenerator);
}
const ALL_ACCREDITATIONS_API = async ( args ) => {
  return apolloClient.query({
    query: ALL_ACCREDITATIONS_QUERY,
    variables: { userId: args.userId, limit: args?.limit, page: args?.page, search: args?.search, orderByField: args?.orderByField, orderDirection: args?.orderDirection, status: args?.status }
  });
}
function* allAccreditationsGenerator({ payload }) {
  const { args } = payload;
  try {
    const { data: { allAccreditations }} = yield call(ALL_ACCREDITATIONS_API, args);
    yield put(allAccreditationsSuccess(allAccreditations));
  } catch (e) {
    handleErrorMessage(e, 'All Accreditations Error', null);
  }
}

export function* watchResendVerificationEmail() {
  yield takeEvery(RESEND_VERIFICATION_EMAIL, resendVerificationEmailGenerator);
}
const RESEND_VERIFICATION_EMAIL_API = async ( args ) => {
  return apolloClient.query({
    query: RESEND_VERIFICATION_EMAIL_QUERY,
    variables: { email: args?.email }
  });
}
function* resendVerificationEmailGenerator({ payload }) {
  const { args } = payload;
  try {
    const { data: { resendVerificationEmail }} = yield call(RESEND_VERIFICATION_EMAIL_API, args);
    handleSuccessMessage(resendVerificationEmail, 'Verification Email Resent');
    yield put(resendVerificationEmailSuccess(resendVerificationEmail));
  } catch (e) {
    handleErrorMessage(e, 'Resend Verification Email Error', null);
    yield put(resendVerificationEmailError());
  }
}

export function* watchAccreditationsSummary() {
  yield takeLatest(ACCREDITATIONS_SUMMARY, accreditationsSummaryGenerator);
}
const ACCREDITATIONS_SUMMARY_API = async () => {
  return apolloClient.query({
    query: ACCREDITATIONS_SUMMARY_QUERY,
    variables: {}
  });
}
function* accreditationsSummaryGenerator() {
  try {
    const { data: { accreditationsSummary }} = yield call(ACCREDITATIONS_SUMMARY_API);
    yield put(accreditationsSummarySuccess(accreditationsSummary));
  } catch (e) {
    handleErrorMessage(e, 'Accreditations Summary Error', null);
  }
}

export function* watchDeleteAccreditation() {
  yield takeEvery(DELETE_ACCREDITATION, deleteAccreditationGenerator);
}
const DELETE_ACCREDITATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: DELETE_ACCREDITATION_QUERY,
    variables: {deleteAccreditationId: args.id}
  });
}
function* deleteAccreditationGenerator({payload}) {
  const { args } = payload;
  try {
    const { data: { deleteAccreditation }} = yield call(DELETE_ACCREDITATION_API, args);
    handleSuccessMessage(deleteAccreditation, 'Accreditation Deleted');
    yield put(deleteAccreditationSuccess());
  } catch (e) {
    handleErrorMessage(e, 'Delete Accreditation Error', null);
  }
}

export function* watchRestoreAccreditation() {
  yield takeEvery(RESTORE_ACCREDITATION, restoreAccreditationGenerator);
}
const RESTORE_ACCREDITATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: RESTORE_ACCREDITATION_QUERY,
    variables: {restoreAccreditationId: args.id}
  });
}
function* restoreAccreditationGenerator({payload}) {
  const { args } = payload;
  try {
    const { data: { restoreAccreditation }} = yield call(RESTORE_ACCREDITATION_API, args);
    handleSuccessMessage(restoreAccreditation, 'Accreditation Restored');
    yield put(restoreAccreditationSuccess());
  } catch (e) {
    handleErrorMessage(e, 'Restore Accreditation Error', null);
  }
}

export function* watchPostponeAccreditationExpiration() {
  yield takeEvery(POSTPONE_ACCREDITATION_EXPIRATION, postponeAccreditationExpirationGenerator);
}
const POSTPONE_ACCREDITATION_EXPIRATION_API = async (args) => {
  return apolloClient.mutate({
    mutation: POSTPONE_ACCREDITATION_EXPIRATION_QUERY,
    variables: {postponeAccreditationExpirationId: args.id, expiresAt: args.expires_at, isFuture: args?.is_future}
  });
}
function* postponeAccreditationExpirationGenerator({payload}) {
  const { args } = payload;
  try {
    const { data: { postponeAccreditationExpiration }} = yield call(POSTPONE_ACCREDITATION_EXPIRATION_API, args);
    handleSuccessMessage(postponeAccreditationExpiration, args.is_future ? 'Accreditation Expiration Postponed' : 'Accreditation Marked as Expired');
  } catch (e) {
    handleErrorMessage(e, 'Postpone Accreditation Expiration Error', null);
  }
}

export default function* rootSaga() {
  yield all([
    // Sorted from most common to least common
    // User effects
    fork(watchUserSummary),
    fork(watchUpdatePersonalInformation),
    fork(watchUpdatePassword),
    fork(watchResendVerificationEmail),
    fork(watchDeleteUser),
    // Accreditation effects
    fork(watchAccreditationsSummary),
    fork(watchAllAccreditations),
    fork(watchPostponeAccreditationExpiration),
    fork(watchDeleteAccreditation),
    fork(watchRestoreAccreditation),
    // Notifications Effects
    fork(watchGetNotifications),
    fork(watchMarkNotificationsAsSeen),
  ]);
  
}

import {
  GET_PAYMENT_METHODS,
  GET_PAYMENT_METHODS_SUCCESS,
  GET_PAYMENT_METHODS_ERROR,
  PAYMENT,
  PAYMENT_SUCCESS,
  PAYMENT_ERROR,
} from 'redux/contants';

const INIT_STATE = {
  loading: false,
  error: null,
  paymentMethods: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case PAYMENT:
      return { ...state, loading: true, error: null };
    case PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case PAYMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: '',
      };
    case GET_PAYMENT_METHODS:
      return { ...state, loading: true, error: null };
    case GET_PAYMENT_METHODS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        paymentMethods: action.payload,
      };
    case GET_PAYMENT_METHODS_ERROR:
      return {
        ...state,
        loading: false,
        error: '',
        paymentMethods: [],
      };
    default:
      return { ...state };
  }
};
